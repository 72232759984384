import React from "react";
import { ButtonWithHoverEffect} from "sacatelle-common";
export const GetStartedButton = (props) => {
    return <ButtonWithHoverEffect
            buttonHandler={() => window.location.href = "/products?index=1"}
              centered
              title={props.title || "get started"}
              width={"fit-content"}
              height={"50px"}
            />
}
