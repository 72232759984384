import React, {Component} from "react";
import underline from "../../assets/images/faqs/faq-categories/FAQ_Questions_GlowingLine.png";
import "./FaqCategoryItemComponent.scss"
import { Image } from "sacatelle-common";

interface CategoryItemProps{
    id: number,
    image: string,
    imageHover: string,
    title: string,
    description: string,
}

export class FaqCategoryItem extends Component<CategoryItemProps,{}>{
    Base_URL = process.env.REACT_APP_BLOB;
    imgUrl =`../../assets/images/${this.props.image}`
    hoverImgUrl = `../../assets/images/${this.props.imageHover}`
    render(){
        debugger;

        return (
            <div className="faq-category-item-wrapper-border">
                <div className="faq-category-item-wrapper">
                    <Image classes="category-logo" src={require("../../assets/images/" + this.props.image)}  alt="category-logo" />
                    <Image classes="category-logo-hover" src={require("../../assets/images/" + this.props.imageHover)}  alt="category-logo" />
                    <p className="category-title">{this.props.title}</p>
                    <Image classes="underline" src={underline}  alt="underline" />
                    <p className="category-description">{this.props.description}</p>
                </div>
            </div>
           
            )
    }
}
