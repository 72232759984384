import React, { Component } from 'react';
import styles from "./styles/Card.module.scss";
import { ButtonWithHoverEffect } from "sacatelle-common";

interface CardProps {
    whiteBkg?: boolean,
    width: string,
    height: string,
    columnDirection?: "Vertical" | "Horizontal",
    button?: boolean,
    title?: string,
    taLine?: string,
    buttonHref?: string,
    roundedCorner?: string,
    imageUrl?: string,
    introText?: string,
    buttonTitle?: string,
    descriptionWidth: string,
    whiteBackgroundStyleParagraph: boolean,
    isIcon: boolean,
    buttonHandler?: (e)=>void | undefined,

}

export default class Card extends Component<CardProps> {

    render() {
        return (
            <div>
                {!this.props.isIcon && <img className={styles.imageOutsideBoundary} src={this.props.imageUrl} alt="icon that compliments the title"/>}
                <div
                    className={this.props.whiteBkg ? `${styles.whiteBackground} ${styles.borderRadius}` : ` ${styles.borderRadius} ${styles.grayBackground}`}
                    style={{width: `${this.props.width}`, height: `${this.props.height} card-element-container`}}>
                    <div className={`${this.props.isIcon ?  styles.center : styles.centerWhiteContainer} card-inner-section`}>
                        {this.props.isIcon &&  <div style={{paddingTop: "15px"}} className="card-img-container"><img src={this.props.imageUrl} alt="icon that compliments the title"/></div>}
                        <h3 className={styles.swagTitle}>{this.props.title}</h3>
                        <p style={{width: this.props.descriptionWidth}}
                           className={this.props.whiteBackgroundStyleParagraph ? `${styles.whiteContainerParagraphStyle}` : `${styles.grayBackgroundParagraphStyle}`}>{this.props.introText}</p>
                        {this.props.button &&
                        <ButtonWithHoverEffect href={this.props.buttonHref || ""} title={this.props.buttonTitle || ""} buttonHandler={this.props.buttonHandler} width={"150px"} height={"50px"}/>}
                    </div>
                </div>
            </div>

        )
    }
}
