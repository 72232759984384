import React, {FC} from "react";
import style from './Faq.module.scss';
import {Search} from "react-bootstrap-icons"

interface FaqSearchProps {
    onSearchHandler: (data:string) => void;
    closeHandler: () => void;
    searchInput: string;
}

export const FAQSearch:FC<FaqSearchProps> = (props) => {

    return (
        <div className={style.searchWrapper}>
            <form className={style.search} id="search_container">
                <div style={{display: "flex", flexDirection: "row", marginTop: "30px"}}>
                       <Search style={{position: "relative", top: "10px" , left: "20px"}}/>
                    <input
                        className={style.searchInput}
                        type="search"
                        placeholder="Search for a Question..."
                        onChange={e => props.onSearchHandler(e.currentTarget.value)}
                        id="dropdown_target"
                        value={props.searchInput}
                    />
                </div>
            </form>
        </div>
    )
}
