import React, { Component, ReactFragment } from 'react';
import styles from "../styles/OneHalfSplitContainer.module.scss";


interface OneHalfSplitContainerProps{
    title?: ReactFragment;
}

class OneHalfSplitContainer extends Component<OneHalfSplitContainerProps> {
    render() {
        return (
            <>
                    <div className={`${styles.title} ${styles.center}`}>{this.props.title}</div>
                    <div className={styles.oneHalfSplitContainer}>
                        {this.props.children}
                    </div>
            </>
        );
    }
}

export default OneHalfSplitContainer;
