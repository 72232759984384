import React, { Component } from 'react';
import kitSample from "../../../assets/images/grabYourFreeSample/kitSampleSmall.png";
import swagSample from "../../../assets/images/grabYourFreeSample/swagSampleSmall.png";
import styles from "../styles/GrabFreeSampleSection.module.scss";
import title from "../styles/ContainerWithTopicIndicator.module.scss";
import { ModalSacatelle } from 'sacatelle-common';
import SubmitForm from '../organisms/SampleCheckout';
import { MessageSent, DiagonalTextColorBackground } from "sacatelle-common";
import ContainerWithTopicIndicator from '../molecules/ContainerWithTopicIndicator';
import OneHalfSplitContainer from '../molecules/OneHalfSplitContainer';
import { IconWText } from '../atoms/IconWText';
import Card from '../../shared/Card';

interface GrabFreeSampleSelectionState{
    requestedKitType: string,
    isSubmitted: boolean,
    show: boolean
}

class GrabFreeSampleSection extends Component<any,GrabFreeSampleSelectionState> {
    constructor(props) {
        super(props);
        this.state = {
            requestedKitType:"",
            isSubmitted: false,
            show: false,
        }
    }
    setSubmitted = () => this.setState({ isSubmitted: true });

    samples = [
        {
            buttonTitle: "swag sample",
            description: "Custom samples made to order, free with your order confirmation."+
                " Choose from bags and apparel, to packaging and merch.",
            image: swagSample
        },
        {
            buttonTitle: "kit sample",
            description: "Request a free sample kit to get an idea of what it's like to unbox a creative merch kit. Shipped overnight at no charge.",
            image: kitSample
        }
    ]
    requestedKit = ""
    buttonHandler = (e) => {
        if(this.state.isSubmitted){
            this.setState({
                isSubmitted: false
            })
        }
        const kitname = e.target.textContent
        this.setState({ requestedKitType: kitname })
        this.setState({ show: true })
    }

    modal = (isMobile) => (

        <ModalSacatelle
            show={this.state.show}
            onHide={() => this.setState({ show: false })}>
            {!this.state.isSubmitted && <SubmitForm
                // subject="New Sample Request"
                isMobile={isMobile ? true : false}
                setSubmitted={this.setSubmitted}
                requestedKitType={this.state.requestedKitType.charAt(0).toUpperCase() + this.state.requestedKitType.slice(1)}
            />}
            {this.state.isSubmitted && <MessageSent/>}

        </ModalSacatelle>)

    renderCard = (sample) => <div style={{ marginTop: this.props.viewWidth < 992 ? 25 : 0, marginBottom: this.props.viewWidth < 992 ? 25 : 0, textAlign: "center", transform: 'inherit'}}>
        <Card  buttonHandler={this.buttonHandler} imageUrl={this.props.img ? this.props.img : sample.image} width={"100%"} height={"354px"} button={true} buttonTitle={sample.buttonTitle}
               introText={sample.description} whiteBkg={true} descriptionWidth={"264px"} whiteBackgroundStyleParagraph={true} isIcon={false} />
    </div>

    render() {
        if (this.props.viewWidth > 992) {
            return (
                <section className={styles.container}>
                    <ContainerWithTopicIndicator topicName={"request a free sample"}/>
                    <OneHalfSplitContainer title={ <>grab your <DiagonalTextColorBackground paddingLeft={8} paddingRight={14} marginLeft={10} noVerticalAlign={true}>free</DiagonalTextColorBackground> sample</>} >
                        {
                            this.samples.map((sample, index) => {
                                return (
                                    <Card  buttonHandler={this.buttonHandler} imageUrl={index === 0 && this.props.img ? this.props.img : sample.image} width={"400px"} height={"460px"} button={true} buttonTitle={sample.buttonTitle}
                                           introText={sample.description} whiteBkg={true} descriptionWidth={"264px"} whiteBackgroundStyleParagraph={true} isIcon={false} />
                                )
                            })
                        }
                    </OneHalfSplitContainer>

                    {this.modal(false)}
                </section>
            )} else {
            return <div className="grab-free-sample-mobile">
                <div style={{ textAlign: "center", width: "100%", marginTop: 30, marginBottom: 50}}>
                    {this.props.viewWidth < 992 ? <IconWText textClassName={title.breadCrumb} text={"GRAB YOUR FREE SAMPLE"} src={require("../../../assets/images/CheckboxSelectedMedium.png")}/> :
                        <DiagonalTextColorBackground fontSize={24} paddingLeft={14} paddingRight={14}>
                            GRAB YOUR {" "} FREE SAMPLE
                        </DiagonalTextColorBackground>}</div>
                {
                    this.samples.map((sample, index) => {
                        if (this.props.firstOnly) return index === 0 && this.renderCard(sample)
                        else return this.renderCard(sample)
                    })
                }
                {this.modal(true)}
            </div>
        }
    }
}

export default GrabFreeSampleSection;
