import React, {Component, useState} from "react";
import {useMutation, gql} from "@apollo/client";
import styles from "./Footer.module.css";


export const Subscribe = (props) => {
    const [email, setEmail] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    const handleChange = (event) => {
        event.preventDefault();
        const userEmail = event.currentTarget.value
        setEmail(userEmail)
    }

    const validateForm = () => {
        const isEmail = validEmailRegex.test(email)
        if (!isEmail) {
            setErrorMessage("Please use a valid email")
        } else {
            return true
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setErrorMessage("")
        if (validateForm()) {
            console.log('email sent')
            // mutateEmail({
            //     variables: {
            //         email: email
            //     }
            // })
            setSuccessMessage("Email has been sent")
            setEmail("")
        }
    }

    const UPDATE_NEWSLETTER_EMAIL = gql`
        mutation insertNewsletterEmail($field: String,  ){
            insertEmail(field: $field )
        }
    `
    const [mutateEmail] = useMutation(UPDATE_NEWSLETTER_EMAIL)


    // 	const url="http://aws.sacatelle.com:81/api/v1/newsletter";
    // 	const headers = new Headers();
    // 	headers.append("Content-Type", "application/json");
    // 	const options={
    // 		method : "POST",
    // 		headers,
    // 		body : JSON.stringify({ email, }),
    // 	};
    //
    // 	fetch(url, options)
    // 	.then( response =>  response.json() )
    // 	.then( data => data.status === "subscribed"?
    // 		alert("CONGRATULATIONS! YOU HAVE BEEN SUBSCRIBED!"):
    // 		alert(data.message.split("Use")[0])
    // 	)
    // 	.catch(e => alert(e.message));
    // }

    return (
        <>
            {/*  <!-- Begin Mailchimp Signup Form -->*/}
            <div id="mc_embed_signup">
                <form
                    action="https://sacatelle.us13.list-manage.com/subscribe/post?u=8156e6cfa343488c1ded2d472&amp;id=8f259eb672"
                    method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                    className="validate" target="_blank" noValidate>
                    <div id="mc_embed_signup_scroll">
                        <h4 style={{textAlign: "center", color: "white"}} className={styles.title}>NEWSLETTER</h4>

                        <div className="mc-field-group">
                            <input className={styles.input} placeholder="ENTER YOUR EMAIL ADDRESS HERE" onChange={(e) => handleChange(e)} type="email" value={email} name="EMAIL"
                                   id="mce-EMAIL"/>
                        </div>
                        <div hidden={true}><input type="hidden" name="tags" value="702281"/></div>
                        <div id="mce-responses" className="clear">
                            <div className="response" id="mce-error-response"
                                 style={{display: "none"}}> </div>
                            <div className="response" id="mce-success-response"
                                 style={{display: "none"}}> </div>
                        </div>
                        {/*  <!-- real people should not fill this in and expect good things - do
                        not remove this or risk form bot signups-->*/}
                        <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input
                            type="text" name="b_8156e6cfa343488c1ded2d472_8f259eb672" tabIndex={-1}
                            /></div>
                        <div className="clear">
                            <div className="flex-row">
                            <input className={styles.button} style={{color: "white"}} type="submit" value="Subscribe" name="subscribe"
                                                  id="mc-embedded-subscribe" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {/*<!--End mc_embed_signup-->*/}
        </>
)

        }
        var validEmailRegex =
        RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
