import React, {FC, useState} from "react";
import contactMapUsa from "../../assets/images/mapLocation/contact-map-usa.svg";
import contactMapIndicator1 from "../../assets/images/mapLocation/contact-map-andicator-1.png";
import contactMapIndicator2 from "../../assets/images/mapLocation/contact-map-andicator-2.png";
import contactMapIndicator3 from "../../assets/images/mapLocation/contact-map-andicator-3.png";
import contactMapIndicator8 from "../../assets/images/mapLocation/contact-map-andicator-8.png";
import contactMapEurope from "../../assets/images/mapLocation/contact-map-europe.svg";
import contactMapIndicator4 from "../../assets/images/mapLocation/contact-map-andicator-4.png";
import contactMapAsia from "../../assets/images/mapLocation/contact-map-asia.svg";
import contactMapIndicator5 from "../../assets/images/mapLocation/contact-map-andicator-5.png";
import contactMapIndicator6 from "../../assets/images/mapLocation/contact-map-andicator-6.png";
import contactMapIndicator7 from "../../assets/images/mapLocation/contact-map-andicator-7.png";
import style from "./Contact.module.scss"

export const LocationMap: FC = () => {
const [active, setActive] = useState(1)
    return (
      <section className="contact-locations">
        <div className={style.container}>
          <div className="location-tab">
            <ul
                style={{display: "flex", justifyContent: "center"}}
                className="tab-nav"
            >
              <li className={active === 1 ? "active" : ""}>
                <a style={{cursor: "pointer"}} onClick={() => {setActive(1)}}>USA</a>
              </li>
              <li className={active === 2  ? "active" : ""}>
                <a style={{cursor: "pointer"}} onClick={() => {setActive(2)}}>EUROPE</a>
              </li>
              <li className={active === 3  ? "active" : ""}>
                <a style={{cursor: "pointer"}} onClick={() => {setActive(3)}}>ASIA</a>
              </li>
            </ul>
            <ul className="switcher">
              <li className={active === 1 ? "active1" : "notActive"}>
                <div className="map-container">
                  <img className="map" src={contactMapUsa} />
                  <img
                    className="andicator andicator-1"
                    src={contactMapIndicator1}
                  />
                  <img
                    className="andicator andicator-2"
                    src={contactMapIndicator2}
                  />
                  <img
                    className="andicator andicator-3"
                    src={contactMapIndicator3}
                  />
                  <img
                    className="andicator andicator-8"
                    src={contactMapIndicator8}
                  />
                  <div className="location location-1">
                    <h4 className="title">LA Main Office </h4>
                    <address id="location-address">
                      5632 W Washington Blvd
                      <br />
                      Los Angeles, CA 90016
                      <br />
                      (310) 967-7753
                    </address>
                  </div>
                  <div className="location location-2">
                    <h4 className="title">MIA WAREHOUSE </h4>
                    <address id="location-address">
                      Florida, USA
                      <br />
                    </address>
                  </div>
                  <div className="location location-3">
                    <h4 className="title">NY DESIGN </h4>
                    <address id="location-address">New York, USA</address>
                  </div>
                  <div className="location location-8">
                    <h4 className="title">LA WAREHOUSE </h4>
                    <address id="location-address">California, USA</address>
                  </div>
                </div>
              </li>
              <li className={active === 2 ? "active1" : "notActive"}>
                <div className="map-container">
                  <img className="map" src={contactMapEurope} />
                  <img
                    className="andicator andicator-4"
                    src={contactMapIndicator4}
                  />
                  <div className="location location-4">
                    <h4 className="title">Lisbon Design </h4>
                    <address id="location-address">Portugal</address>
                  </div>
                </div>
              </li>
              <li className={active === 3 ? "active1" : "notActive"}>
                <div className="map-container">
                  <img className="map" src={contactMapAsia} />
                  <img
                    className="andicator andicator-5"
                    src={contactMapIndicator5}
                  />
                  <img
                    className="andicator andicator-6"
                    src={contactMapIndicator6}
                  />
                  <img
                    className="andicator andicator-7"
                    src={contactMapIndicator7}
                  />
                  <div className="location location-5">
                    <h4 className="title">Changzhou Manufactory </h4>
                    <address id="location-address">
                      More Information On Request
                    </address>
                  </div>
                  <div className="location location-6">
                    <h4 className="title">Baiyun Manufactory </h4>
                    <address id="location-address">
                      China
                      <br />
                      More Information On Request
                    </address>
                  </div>
                  <div className="location location-7">
                    <h4 className="title">Hanoi Manufactory </h4>
                    <address id="location-address">
                      Vietnam
                      <br />
                      More Information On Request
                    </address>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    );

}
