import React, {Component} from 'react';
import styles from "../styles/OneThirdSlpitContainer.module.scss"



class OneThirdSplitContainer extends Component {
    render() {
        return (
            <div className={styles.oneThirdTwoThird}>
                {this.props.children}
            </div>
        );
    }
}

export default OneThirdSplitContainer;
