import React, {useEffect, useState} from "react";
import "./About.scss";
import {DiagonalTextColorBackground} from "sacatelle-common";
import { OurMission } from "./organisms/OurMission";
import { OurMission as OurMissionMobile } from "./organisms/OurMission/mobile";
import { EmployeeSlider } from "./molecules/EmployeeSlider";
import { WhoWeAre } from "./organisms/WhoWeAre";
import { WhoWeAre as WhoWeAreMobile } from "./organisms/WhoWeAre";
import WhatWeCareAbout from "./organisms/WhatWeCareAbout";
import WhatWeCareAboutMobile from "./organisms/WhatWeCareAbout/mobile";
import JoinCrew from "./organisms/JoinCrew/JoinCrew";

export const About = () => {
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => setWidth(window.innerWidth));

    useEffect(() =>{
        window.location.href = "/about/#about"
    }, [])

  return (
    <div id="about" style={{ marginTop: "40px", scrollMarginTop: "56px"}} className="about-page">
      {width > 1014 ? <OurMission /> : <OurMissionMobile width={width} />}
      <div
        style={{
          background: "#101924",
          width: "100%",
          paddingTop: 100,
        }}
      >
        <div
          style={{
            margin: "0 auto",
            width: "calc(100% - 80px)",
            marginBottom: width > 640 ? 220 : 0,
          }}
        >
          <div style={{fontStyle:"italic", position: "relative", top: "-120px", left: "-25%"}} className={'meetOurCrew'}>
              <DiagonalTextColorBackground backgroundColor={"#101923"} paddingLeft={12}
                                         paddingRight={12}
                                         marginLeft={5}
                                         noVerticalAlign={true}
                                         fontSize={30} width={"300px"}>MEET OUR EXECUTIVE CREW</DiagonalTextColorBackground>

          </div>
          <EmployeeSlider width={width} />
        </div>

        {width > 864 ? <WhoWeAre /> : <WhoWeAreMobile />}
        {width > 864 ? (
          <WhatWeCareAbout />
        ) : (
          <WhatWeCareAboutMobile width={width} />
        )}
          <JoinCrew />
      </div>
    </div>
  );
};
