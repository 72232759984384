import React from "react";
import {BaseUserForm} from "sacatelle-common";
import {gql, useMutation} from "@apollo/client";


const SampleCheckout = (props) => {

    const SEND_CLIENT_EMAIL = gql`
        mutation sendDesignerEmail($to: String!, $message: String!,  $subject: String!  ){
            sendEmail(to: $to, message: $message, subject: $subject)
        }
    `
    const [mutateSendEmail] = useMutation(SEND_CLIENT_EMAIL)

    const onSubmit = (firstName: string, lastName: string, email: string, phone?: string, company?: string, e?:any) => {
        e.preventDefault();
        mutateSendEmail({
            variables: {
                to: "info@sacatelle.com",
                subject: props.requestedKitType,
                message: `<div>
                        <p><h4>Requested by:</h4> ${lastName + ", " + firstName}</p>
                      <p><h4>Email:</h4>${email}</p> 
                        <p><h4>Phone:</h4>${phone}</p> 
                        <p><h4>Company:</h4>${company}</p>
                        </div>`,
            }, onCompleted: (data) => {
                // data.sendEmail
                props.setSubmitted()
            }
        })
    };
    return (<BaseUserForm submitCallback={onSubmit}
                          title={props.requestedKitType}>
            <div>
                <div hidden={true}>
                    <input type="hidden" name="tags"
                           value={props.requestedKitType === "Swag sample" ? "702285" : "702289"}/>
                </div>
                <div style={{position: "absolute", left: "-5000px"}}
                     aria-hidden="true"><input type="text"
                                               name="b_8156e6cfa343488c1ded2d472_8f259eb672"
                                               tabIndex={-1} value=""/>
                </div>
            </div>
        </BaseUserForm>
    )

}

export default SampleCheckout
