import React, {FC} from "react";
import {FaqCategory} from "./FaqCategory";
import {FaqCategoryItem} from "./FaqCategoryItem";
import style from "./Faq.module.scss"


interface FaqListProps{
    FaqData: FaqCategory[],
    targetId: string,
    clickHandler: (key: number) => void,
}

export const FAQCategoriesList:FC<FaqListProps> = (props) => {

        return (
            <div className={style.categoriesWrapper}>
                    {props.FaqData.map((item, index) =>
                            <div key={index} onClick={() => props.clickHandler(index)} >
                                <FaqCategoryItem  key={index}
                                                  id={index}
                                                  image={item.firstImage}
                                                  imageHover={item.secondImage}
                                                  title={item.title}
                                                  description={item.description}
                                />
                            </div>
                    )
                    }
            </div>
        )

}
