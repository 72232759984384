import React, { useState } from "react";

const WhatWeCareAbout = ({ width }) => {
  const [category, setCategory] = useState(2); // Initial category is 2 ("Growth")

  return (
    <div style={{ marginTop: 60 }}>
      <div style={{ color: "#fff", marginBottom: 0 }}>
        <span style={{ fontSize: 26, fontStyle: "italic" }}>
          WHAT WE CARE ABOUT
        </span>
      </div>

      <div
        className="flex-column"
        style={{ maxWidth: "max-content", margin: "0 auto" }}
      >
        <div
          className="flex-row"
          style={{
            marginLeft: width > 480 ? "1%" : 0,
            marginRight: width > 480 ? "1%" : 0,
            marginBottom: 30,
            marginTop: 40,
          }}
        >
          <div
            className="what-we-care-category-mobile"
            style={{ color: "#fff", opacity: category === 1 ? 1 : 0.8 }}
            onClick={() => setCategory(1)}
          >
            CREATIVITIY
          </div>
          <div
            className="what-we-care-category-mobile"
            style={{ color: "#fff", opacity: category === 2 ? 1 : 0.8 }}
            onClick={() => setCategory(2)}
          >
            GROWTH
          </div>
          <div
            className="what-we-care-category-mobile"
            style={{
              color: "#fff",
              opacity: category === 3 ? 1 : 0.8,
            }}
            onClick={() => setCategory(3)}
          >
            CULTURE
          </div>
        </div>
        <div className="what-we-care-box-mobile">
          <span style={{ display: category === 1 ? "block" : "none" }}>
            There is plenty of blank space out there, make something out of it.{" "}
            <br />
            <br />
            Creativity or a desire to be creative is at the basis of everything
            we do, as humans in general, and for us as a company. Our passion
            for creativity grew out of the massive success brands to have found
            with creative branding, encouraging not only the work we do but our
            very culture to encourage creative thinking.
          </span>
          <span style={{ display: category === 2 ? "block" : "none" }}>
            The universe is continually expanding, so are we.
            <br />
            <br /> We think we are pretty awesome, but that doesn't mean there
            isn't plenty of room for improvement. We are constantly reviewing
            and improving our systems to ensure the best possible experience for
            our clients and to make sure that everyone is happy - because that
            is what matters the most. Promoting from within, recognizing and
            developing alternative talents, and looking for talent from diverse
            back- grounds. These are core principles behind Sacatelle now and
            well into the future.
          </span>
          <span style={{ display: category === 3 ? "block" : "none" }}>
            Have fun...pretty simple.
            <br />
            <br />
            It’s easy to lose the battle of work vs life, but we are all about
            balance. A happy crew is a successful crew, and so we try and have
            as much fun as possible while still getting everything done. Ping
            pong, football, Basketball, and BBQs all boost morale plus they also
            tend to lead to some killer brainstorm sessions. We are all kids at
            heart and that is the beauty of entering the Sacatelle universe. You
            get to fulfill all of those childhood astronaut fantasies - plus get
            some work done.
          </span>
        </div>
      </div>
    </div>
  );
};

export default WhatWeCareAbout;
