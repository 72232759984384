import React, {useEffect, useState} from "react";
import './Faq.module.scss';
import {FAQSearch} from "./FAQSearch";
import {FAQCategoriesList} from "./FAQCategoriesList";
import {FaqCategory} from "./FaqCategory";
import {ModalSacatelle, Spinner} from "sacatelle-common";
import {FAQQuestionsList} from "./FAQQuestionsList"
import {SearchList} from "./SearchList";
import { gql, useQuery } from "@apollo/client";


export const Faq = () => {
    const [title, setTitle] = useState('')
    const [categories, setCategories] = useState<any[]>([])
    const [questions, setQuestions] = useState<any[]>([])
    const [searchInput, setSearchInput] = useState('')
    const [questionSelected, setQuestionSelected] = useState('')
    const [show, setShow] = useState(false)

        var query = gql`
        query
            faqs{
                faqs{
              id,
              title,
              description,
              secondImage,
              firstImage,
              faqDetails{
                id,
                title,
                description
              }
            }
            }
          
        `;
    const {loading} = useQuery(query, {onCompleted: (result) => {
        setCategories([...result.faqs])
        checkQueryParams()
    }})
    const checkQueryParams = ()=>{
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get("id");
        const questionSelected = queryParams.get("questionSelected");
        if (id && questionSelected) {
            questionSelectedHandler(Number(id), questionSelected);
            setShow(true )
        }
    }

    useEffect(()=>{
        window.location.href = "/faq/#faq"
    },[])

   const onSearchHandler = (searchInput: string) =>  {
        setSearchInput(searchInput)
    }

   const onClickHandler = (key: number) => {
       setShow(true)
       setTitle(categories[key].title)
       setQuestionSelected('')
       setQuestions([...categories[key].faqDetails])
       setSearchInput('')
   }

   const questionSelectedHandler = (key: number, questionSelected: string) => {
        categories.forEach(item => {
            if(item.id === key){
                // setShow(true)
                setTitle(item.title)
                setQuestions([...item.faqDetails])
                setQuestionSelected(questionSelected)
            }
        })
    }
    
    const closeHandler = () => {
      setSearchInput('')
    }
    const foundText: FaqCategory[] = [] ;
    categories.map((item, index) => {
        item.faqDetails.map( question => {
            if(question.title.toLowerCase().includes(searchInput.toLowerCase()) || question.description.toLowerCase().includes(searchInput.toLowerCase())){
                foundText.push({
                    title: item.title,
                    id: item.id,
                    description: item.description,
                    firstImage: item.firstImage,
                    secondImage: item.secondImage,
                    faqDetails: item.faqDetails,
                    question: question.title
                })
            }
        })
    })
    return (
        loading ?
            <>
             <Spinner placeholderType={"categories"}/>
            </>:
            <div id={"faq"} className="faq-wrapper">
                <div className="container">
                    <div style={{marginTop: "56px"}}/>
                    <FAQSearch searchInput={searchInput} onSearchHandler={onSearchHandler} closeHandler={closeHandler}/>
                    {searchInput && <SearchList targetId={'modal-overflow'}
                                                foundItems={foundText}
                                                questions={questions}

                    />}
                    <FAQCategoriesList targetId={'modal-overflow'} FaqData={categories} clickHandler={onClickHandler}/>
                    <ModalSacatelle
                        show={show}
                        onHide={() => {
                            setShow( false )
                        }}
                        title={title}>
                        <FAQQuestionsList
                            questions={questions}
                            questionSelected={questionSelected} />
                    </ModalSacatelle>
                </div>
            </div>
    )

}
