import React from "react";
import { HomeBannerItem } from "../atoms/HomeBannerItem";
import HomeBannerItemMobile from "../atoms/HomeBannerItemMobile";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../styles/Home.scss"

interface HomeBannerState {
  width: number;
  height: number;
  activeIndex: number | undefined;
  range: number;
  isMobile: boolean;
}
export class HomeBanner extends React.Component<{}, HomeBannerState> {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight - 61,
      activeIndex: window.innerWidth < 786 ? 0 : undefined,
      range: 1,
      isMobile: window.innerWidth < 786,
    };
  }
  setActiveIndex(i: number | undefined) {
    this.setState({
      activeIndex: i,
    });
  }
  handleResize() {
    this.setState({
      height: window.innerHeight - 61,
      width: window.innerWidth,
      isMobile: window.innerWidth < 786,
    });
    // if (window.innerWidth < 786)
    //     $(".navbar").removeClass("transparent-background")
    // if (window.scrollY > 120){
    //   $(".navbar").addClass("transparent-background")
    // }

  }
  handleScroll(event){
    // var scrollTop = window.scrollY;
    // if (scrollTop > 120){
    //   $(".navbar").removeClass("transparent-background")
    // }
    // else {
    //   $(".navbar").addClass("transparent-background")
    // }
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize.bind(this));
    window.addEventListener('scroll', this.handleScroll);
    // if (window.innerWidth > 786)
      // $(".navbar").addClass("transparent-background")
  }
  componentWillUnmount(){
    // $(".navbar").removeClass("transparent-background")
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  handleRange = (e) =>
    this.setState({ range: e.target.value, activeIndex: e.target.value - 1 });
  render() {
    return (
      <div
        style={{
          width: this.state.width,
          height: this.state.isMobile ? this.state.height + 5: this.state.height,
          overflow: "hidden",
          backgroundColor: "#D9D8D6",
        }}
      >
        {this.state.isMobile ? (
          <Carousel
            responsive={responsive}
            removeArrowOnDeviceType={["mobile"]}
            dotListClass="white-range"
            customTransition={"transform 300ms linear"}
            transitionDuration={0}
            minimumTouchDrag={0}
            draggable
            swipeable
            showDots
            infinite
          >
            {this.bannerItems.map((banner, i) => (
              <HomeBannerItemMobile
                  key={i}
                id={i}
                banner={banner}
                height={this.state.height + 5}
              />
            ))}
          </Carousel>
        ) : (
          <>
            {this.bannerItems.map((x, i) => {
              var width =
                i == 0
                  ? (this.state.width / 4) * 1.3
                  : i == 3
                  ? (this.state.width / 4) * 0.7
                  : this.state.width / 4;
              if (i == this.state.activeIndex) width = width * 2;
              else if (this.state.activeIndex != undefined)
                if (this.state.activeIndex == 0) width = width * (1.4 / 3);
                else width = width * (2 / 3);
              return (
                <HomeBannerItem
                  title={x.title}
                  hoverCallback={this.setActiveIndex.bind(this)}
                  height={this.state.height}
                  width={width}
                  key={i}
                  index={i}
                  isRight={false}
                  isLeft={false}
                  selectedIndex={this.state.activeIndex}
                  srcString={x.srcString}
                  backgroundColor={x.backgroundColor}
                />
              );
            })}
          </>
        )}
      </div>
    );
  }
  bannerItems = [
    {
      srcString: "Bag.svg",
      title: "Bags",
      backgroundColor: "#19D3C5",
      index: 1
    },
    {
      title: "Packaging",
      srcString: "Packaging.svg",
      backgroundColor: "#101923",//rgb 16 25 35
      index: 2
    },
    {
      title: "Apparel",
      srcString: "Sweater.svg",
      backgroundColor: "#FF9015",
      index:3
      //rgb 255 144 21
    },
    {
      srcString: "Merch.svg",
      backgroundColor: "#D9D8D6",
      title: "Merchandise",
      index:4
    },
  ];
}

const responsive = {
  desktop: {
    breakpoint: { max: 9000, min: 786 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 786, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};
