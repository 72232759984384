import React, { useState } from "react";
import { useLocation } from "react-router-dom";
// Images:
import CustomApparel from "../../assets/images/custom-apparel.png";
import CustomApparelSample from "../../assets/images/custom-apparel-sample.png";
import FedexImg from "../../assets/images/fedex-swag.png";
import CustomBagsImg from "../../assets/images/custom-bags.png";
import CustomPackagingImg from "../../assets/images/custom-packaging.png";
import CustomMerchImg from "../../assets/images/custom-merch.png";
import ShirtAndBag from "../../assets/images/shirt-and-bag.png";
import CustomPackagingSampleImg from "../../assets/images/custom-packaging-sample.png";
import CustomMerchSampleImg from "../../assets/images/custom-merch-sample.png";
import MerchShops, { MerchShopsMobile } from "../home/organisms/MerchShops";
import WeBuildSwagSection from "../home/organisms/WeBuildSwagSection";
import GrabFreeSampleSection from "../home/organisms/GrabFreeSampleSection";
import SwagSolutionSection from "../home/organisms/SwagSolutionSection";
import FollowOurJourney from "../home/organisms/FollowOurJourney";
import { Contact } from "../contact/Contact";

export const Landing = () => {
  const size = {width: window.innerWidth, height: window.innerHeight};
  const location = useLocation();
  let [
    weBuildSwagImg,
    grabFreeSampleImg,
    weBuildSwagTitle,
    weBuildSwagTitleBlack,
    weBuildSwagSubtitle,
  ] = ["", "", "", "", ""];

  // Dynamic page rendering according to current URL:
  switch (location.pathname) {
    case "/sac/custom-swag":
      weBuildSwagTitle = "CUSTOM";
      weBuildSwagTitleBlack = "SWAG";
      weBuildSwagSubtitle = "PROMOTIONAL PRODUCTS";
      grabFreeSampleImg = ShirtAndBag;
      break;
    case "/sac/corporate-swag":
      weBuildSwagTitle = "CORPORATE";
      weBuildSwagTitleBlack = "SWAG";
      weBuildSwagSubtitle = "GIFTS & kits FOR CLIENTS and EMPLOYEES";
      grabFreeSampleImg = ShirtAndBag;
      break;
    case "/sac/custom-apparel":
      weBuildSwagTitle = "CUSTOM";
      weBuildSwagTitleBlack = "APPAREL";
      weBuildSwagSubtitle = "WE DESIGN IT, PRINT IT & SHIP IT";
      weBuildSwagImg = CustomApparel;
      grabFreeSampleImg = CustomApparelSample;
      break;
    case "/sac/custom-bags":
      weBuildSwagTitle = "CUSTOM";
      weBuildSwagTitleBlack = "BAGS";
      weBuildSwagSubtitle = "WE DESIGN IT, PRINT IT & SHIP IT";
      weBuildSwagImg = FedexImg;
      grabFreeSampleImg = CustomBagsImg;
      break;
    case "/sac/custom-packaging":
      weBuildSwagTitle = "CUSTOM";
      weBuildSwagTitleBlack = "packaging";
      weBuildSwagSubtitle = "WE DESIGN IT, PRINT IT & SHIP IT";
      weBuildSwagImg = CustomPackagingImg;
      grabFreeSampleImg = CustomPackagingSampleImg;
      break;
    case "/sac/custom-merch":
      weBuildSwagTitle = "CUSTOM";
      weBuildSwagTitleBlack = "Merch";
      weBuildSwagSubtitle = "WE DESIGN IT, PRINT IT & SHIP IT";
      weBuildSwagImg = CustomMerchImg;
      grabFreeSampleImg = CustomMerchSampleImg;
      break;
    case "/sac/swag-kits":
      weBuildSwagTitle = "SWAG";
      weBuildSwagTitleBlack = "KITS";
      weBuildSwagSubtitle = "WE DESIGN IT, PRINT IT & SHIP IT";
      // Create new component "Send Swag Anywhere"
      break;
    case "/sac/merch-shops":
      break;
  }

  return (
    <>
      {location.pathname.includes("merch-shops") ? (
        <>{size.width > 920 ? <MerchShops /> : <MerchShopsMobile />}</>
      ) : (
        <>
          <WeBuildSwagSection
            viewWidth={size.width}
            img={weBuildSwagImg}
            title={weBuildSwagTitle}
            titleBlack={weBuildSwagTitleBlack}
            subtitle={weBuildSwagSubtitle}
          />
          <GrabFreeSampleSection
            viewWidth={size.width}
            img={grabFreeSampleImg}
            firstOnly
          />
        </>
      )}
      <SwagSolutionSection viewWidth={size.width} />
      {location.pathname.includes("merch-shops") && (
        <WeBuildSwagSection viewWidth={size.width} />
      )}
      <Contact noMap />
      <FollowOurJourney viewWidth={size.width} />
    </>
  );
};

