import React from "react";
import "./Contact.scss";
import { H1TitleWithDiagonalBackground } from "../shared/H1TitleWithDiagonalBackground";

export const CenterTitle = ({ mobile }) => (
  <div>
    <div className="title-center offset-left" style={{ marginTop: 60 }}>
      <span
        style={{
          fontSize: 40,
          minWidth: 280,
          fontStyle: "italic",
          marginBottom: 20,
          fontWeight: "lighter",
          marginLeft: 50,
        }}
      >
        HAVE ANY
      </span>
    </div>
    <div>
      <H1TitleWithDiagonalBackground
        phrase={"QUESTIONS?"}
        width={"310px"}
        fontSize={"40px"}
      />
    </div>
  </div>
);
