import React from "react";
import {XLg} from "react-bootstrap-icons"

interface EmployeeAboutProps {
  info: EmployeeInfo;
  index: number;
  handleToUpdateActiveName: any;
  isActive: boolean;
  boxColor?: string;
  width?: number;
}
export interface EmployeeInfo {
  image: string;
  image_h?: string;
  name?: string;
  role: string;
  data: EmployeeData;
  boxColor?: string;
}
interface EmployeeData {
  name?: string;
  desc: string;
  linkedin: string;
  boxColor?: string;
}

export const EmployeeAbout = (props: EmployeeAboutProps) => {
  var isInRight = Math.floor(props.index / 2) % 2 === 0;

  var crewItemToggle = function () {
    props.isActive
      ? closeHandler()
      : props.handleToUpdateActiveName(props?.info?.name);
  };

  var closeHandler = () => {
    props.handleToUpdateActiveName(" ");
  };
  var posRightClass = isInRight ? "pos-right" : "";
  var active = props.isActive ? " active " : "";
  return (
    <div
      className="crew-item "
      style={{ margin: 0, marginLeft: -20 }}
    >
      {props.info.name && (
        <div
          className={active}
          style={{
            color: "#fff",
          }}
        >
          <div onClick={crewItemToggle} className="crew-item-image">
            <div
              style={{
                transform: `skew(-23deg,0deg) translateY(90px) ${
                  props.width && props.width > 500
                    ? "translateX(90px)"
                    : "translateX(60px)"
                }`,
                background: props.info.boxColor,
                height: 175,
                width: 175,
              }}
            />
            {props.info.image && (
              <img
                width={220}
                height={240}
                src={require("../../../assets/images/about/meet-crew/" +
                  props.info.image)}
                alt=""
              />
            )}
            {props.info.image_h && (
              <img
                width={220}
                height={240}
                src={require("../../../assets/images/about/meet-crew/" +
                  props.info.image_h)}
                alt=""
              />
            )}
          </div>
          <div className="crew-item-title">
            <h4 style={{lineHeight: "30px", margin: "0", letterSpacing: "4px", textTransform: "uppercase"}} >
              {props.info.name}
            </h4>
            <p style={{textTransform: "uppercase"}}>{props.info.role}</p>
          </div>
          <div className={"crew-item-display-card"} style={{zIndex: 10}}>
            <XLg style={{position: "relative", left: "95%",marginBottom: "10px",  color: "white", cursor: "pointer", width: "fit-content"}} onClick={closeHandler}/>
            <h4 style={{textTransform: "uppercase", color: "whitesmoke"}}>{props?.info?.data?.name}</h4>
            <p>{props.info.data.desc}</p>
          </div>
        </div>
      )}
    </div>
  );
};
