import React from "react";
import styles from "../styles/SwageSolutionSection.module.scss";
import CallToActionColumn from "../molecules/CallToActionColumn";
import SwagCardList from "./SwagCardList";
import ContainerWithTopicIndicator from "../molecules/ContainerWithTopicIndicator";
import OneThirdSplitContainer from "../molecules/OneThirdSplitContainer";
import GenericMobileContainer from "../../shared/GenericMobileContainer";

const SwagSolutionSection = ({ viewWidth }) => (
  <section className={styles.container}>
    <ContainerWithTopicIndicator topicName="our services" viewWidth={viewWidth}>
      {viewWidth > 920 ? (
        <OneThirdSplitContainer>
          <CallToActionColumn title="360° Swag solution" />
          <SwagCardList isMobile={false} />
        </OneThirdSplitContainer>
      ) : (
        <div style={{ marginBottom: 50 }}>
          <GenericMobileContainer minHeight={1000} maxHeight={1000}>
            <div
              className={styles.cardListMobileContainer}
              style={{ margin: "0 auto" }}
            >
              <SwagCardList isMobile={true} />
            </div>
          </GenericMobileContainer>
        </div>
      )}
    </ContainerWithTopicIndicator>
  </section>
);

export default SwagSolutionSection;
