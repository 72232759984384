import React, { Component, ReactFragment } from "react";
import styles from "../styles/CTAColumn.module.scss";
import { GetStartedButton } from "./GetStartedButton";

interface OneThirdColumnProps {
    buttonTitle?: string;
  title?: ReactFragment;
  message?: string;
  callUsTagLine?: ReactFragment;
}

class CTAColumn extends Component<OneThirdColumnProps> {
  //360° <br/> Swag
  //                         solution
  message = (
    <div>
      <p className={styles.content}>
        From product manufacturing to distribution, we have you covered. We make
        it easy so that your team can focus on what matters. Designs ready for
        you to review within 24 hours.
      </p>
      <p className={styles.content}> Shipping U.S. and International</p>
    </div>
  );

  render() {
    return (
      <div className={styles.ctaContainerWrapper2}>
        <div className={styles.ctaContainer2}>
          <div>
            <h1 className={styles.title}>{this.props.title}</h1>
          </div>
          {this.props.message ? this.props.message : this.message}
          <div style={{ marginTop: 10, marginBottom: 30 }}>
            <GetStartedButton title={this.props.buttonTitle}/>
            {this.props.callUsTagLine && this.props.callUsTagLine}
          </div>
        </div>
      </div>
    );
  }
}

export default CTAColumn;
