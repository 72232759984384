import React, { ReactElement } from "react";
import { FC } from "react";
import { DiagnolImage } from "sacatelle-common";
import styles from "./styles/MobileFlexibleView.module.scss";
interface MobileFlexibleViewProps{
    title: string | ReactElement;
    imgSrc:string;
}
export const MobileFlexibleView:FC<MobileFlexibleViewProps> = (props) => {
    return (
    <div className={styles.container}>
        <div className={styles.titleContainer}>
            <div className={styles.titleBg}>
                <div className={styles.title} ><span>{props.title}</span></div>
            </div>
        </div>
        <div className={styles.imageContainer}>
            <DiagnolImage isRight={true} width={520} height={360} imageProps={{ style: { maxWidth: 520 }, src: props.imgSrc }} />
        </div>
        <div className={styles.articleContainer}>
            {props.children}
        </div>
    </div>
    );
}