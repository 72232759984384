import React from "react";
import { Link } from "react-router-dom";
import { ButtonTransaparent } from "../../shared/TransaparentButton";

interface HomeBunnerItemMobileProps {
  banner: { [key: string]: any };
  height: number;
  id: number;
}

const HomeBunnerItemMobile = ({
  banner,
  height,
  id,
}: HomeBunnerItemMobileProps) => (
  <div
    className="home-banner-item-mobile"
    style={{
        paddingTop: 50,
      height,
      width: "100%",
      background: banner.backgroundColor,
    }}
  >
    <div
      style={{
        position: "absolute",
        width: "100%",
        bottom: "70px",
        marginLeft: "0",
        textAlign: "center",
        zIndex: 10,
      }}
    >
      <div className="home-banner-item-title">{banner.title}</div>
      <Link style={{textDecoration: "none"}} to={"/products?index=" + banner.index}>
        <ButtonTransaparent text={"Get Started"} />
      </Link>
    </div>
    <img
      src={require("../../../assets/images/home/" + banner.srcString.split(".")[0]+"Hover.svg")}
      className="img-hover-mobile"
      alt="assorted images of swag items"
    />
    <img
      src={require("../../../assets/images/home/" + banner.srcString)}
      className="home-banner-item-img"
      alt="assorted images of swag items"
    />
  </div>
);

export default HomeBunnerItemMobile;
