import React from "react";
import styles from "../styles/WeBuildSwagSection.module.scss";
import {DiagonalTextColorBackground} from "sacatelle-common";
import defaultMobileImg from "../../../assets/images/weBuildSwag/comboSwag.jpg";
import defaultImg
    from "../../../assets/images/weBuildSwag/Website-Banner-002.png";
import CTAColumn from "../molecules/CTAColumn";
import GenericMobileContainer from "../../shared/GenericMobileContainer";
import SwaggerLogos from "./SwaggerLogos";

const WeBuildSwagSection = (props) => {
    const callUsTagLine = (
        <div style={{marginTop:30}}>
            {/* <GetStartedButton /> */}
            <p style={{marginTop:19}}>
                <span className={styles.mediumText2}>OR GIVE US A RING!</span>&nbsp;
                <a href="tel:310-967-7753" className="call-us-text">
                    <span className={styles.phoneNumber}>(310) 967-7753</span>
                </a>
            </p>
        </div>
    );

    return (
        <div style={{backgroundColor: "white", paddingBottom: 60}}>
            {props.viewWidth > 992 ? (
                <div className={styles.container}>
                    <div style={{
                        height: "768px",
                        backgroundColor: "rgb(238,238,238)", margin: "0 auto"
                    }} >
                        <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", position: "relative", height: "768px"}} >
                            <div style={{margin: "0 auto", transform: "translateX(12vw)"}}>
                                <CTAColumn
                                    buttonTitle={"BUILD YOUR SWAG NOW"}
                                    title={
                                        <div style={{textAlign: "center"}}>
                                            {props.title ? props.title : "we've mastered"}{" "}
                                            <span style={{position: "relative", top: "-5px"}}>
                                <DiagonalTextColorBackground
                                    paddingLeft={8}
                                    paddingRight={14}
                                    marginLeft={10}
                                    noVerticalAlign={true}
                                >
                                    {" "}
                                    {props.titleBlack ? props.titleBlack : "Creativity"}
                                </DiagonalTextColorBackground>{" "}
                            </span>

                                            {props.title ? "" : "for you"}
                                            <div style={{textTransform: "uppercase", fontWeight: "300", fontSize: "16px", fontStyle: "normal"}}>anything you can imagine, we can do!</div>
                                        </div>
                                    }
                                    message={" "}
                                    callUsTagLine={callUsTagLine}
                                />
                            </div>
                            <img style={{width: "1270px"}} src={defaultImg} alt={"We build swag hero" +
                                " image"}/>
                        </div>
                    </div>
                    <SwaggerLogos />
                </div>
            ) : (
                <GenericMobileContainer
                    styledTitle={
                        <div style={{ fontSize: 28, fontStyle: "italic", textTransform: "uppercase" , fontWeight: "300"}}>
                            {props.title ? props.title :  "we've mastered"}{" "}
                            <DiagonalTextColorBackground
                                paddingLeft={12}
                                paddingRight={12}
                                marginLeft={5}
                                noVerticalAlign={true}
                                fontSize={28}
                            >
                                {" "}
                                {props.titleBlack ? props.titleBlack : "Creativity"}
                            </DiagonalTextColorBackground>{" "}
                            {props.title ? "" : "for you"}
                        </div>
                    }
                    paddingLeft={0}
                    minHeight={720}
                >
                    <div className="generic-mobile-inner-container">
                        <img
                            src={props.img ? props.img : defaultMobileImg}
                            alt="hero image showing various swag items"
                            style={{
                                margin: "0 auto",
                                width: "100%",
                                marginBottom: 50,
                                maxWidth: 560,
                            }}
                        />
                        {callUsTagLine}
                    </div>
                    <SwaggerLogos />
                </GenericMobileContainer>
            )}
        </div>
    );
};

export default WeBuildSwagSection;
