import React, {FC} from "react";
import {FaqCategory} from "./FaqCategory";
import style from "./searchListItem.module.scss"
import {FaqQuestionListItem} from "./FaqQuestionListItem";

interface QuestionItemProps {
    questionItem: FaqCategory,
    questionSelectedHandler?: (id: number, questionSelected: string) => void;
    targetId: string
    questions: any[]
    questionSelected?: string
}

export const SearchListItem: FC<QuestionItemProps> = (props) => {
    const BASE_URL = process.env.REACT_APP_BLOB;
    const {title, id, question, firstImage, secondImage, faqDetails} = props.questionItem;
    let description = ""
    faqDetails.forEach((item) =>{
        if(item.title === question) {
            description = item.description
        }
    })

    return (
        <div className={style.search_item}
             style={{paddingBottom: "10px", paddingTop: "10px", marginLeft: "30px"}}
        >
            <div className={style.questionItemWrapper}>
                {firstImage && <img className={style.searchItemLogo}
                                    src={require(`../../assets/images/${firstImage}`)} alt="category-logo"/>}
                {secondImage && <img className={style.searchItemLogoHover}
                                     src={require(`../../assets/images/${secondImage}`)} alt="category-logo"/>}
                <div className={style.questionInfo}>
                    <div
                        style={{lineHeight: "12px", fontSize: "0.7rem"}}>{title}</div>
                    <div
                        style={{lineHeight: "20px", letterSpacing: "2px"}}>{question}</div>

                        <FaqQuestionListItem  description={description}/>

                </div>
            </div>
        </div>

    )


}
