import React from "react";
import { Link } from "react-router-dom";


interface ButtonProps {
  text: string;
  isUpperCase?: boolean | false;
  classesToAdd?: string | "";
  onClick?: () => void;
  style?: React.CSSProperties;
  link?: string;
}
export const Button = (props: ButtonProps) => {
  var classes =
    (props.classesToAdd ? props.classesToAdd : "") +
    (props.isUpperCase ? " k-text-uppercase" : "");
  return (
    <>

      {props.link ? (
        <Link to={props.link}>
          <span style={props.style} className={classes}>
            {props.text}
          </span>
        </Link>
      ) : (
        <span style={props.style} onClick={props.onClick} className={classes}>
          {props.text}
        </span>
      )}
    </>
  );
};
