import background from "../../assets/images/titleDiagonalBackground.svg";
import React, {FunctionComponent} from "react";

interface H1TitleWithDiagonalBackgroundProps{
    phrase: string,
    index?: number,
    width: string,
    id?: string,
    fontSize: string
}

export const  H1TitleWithDiagonalBackground: FunctionComponent<H1TitleWithDiagonalBackgroundProps> = (props) => {
    return (
        <div>
            <h1 style={ {
             textTransform: "uppercase" ,
                fontSize: `${props.fontSize}`,
                width: `${props.width}`,
                textAlign: "center",
                backgroundImage: "url(" + `${background}` + ")",
                color:'white', fontStyle: 'italic',
                backgroundPosition: 'left',
                fontWeight: "lighter",
                backgroundSize: 'cover'}} key={props.index} id={props.id}> {props.phrase}</h1>
        </div>
    );
};



