import React, {Component, FC, useContext, useState} from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import "./custom.css";
import "bootstrap/dist/css/bootstrap.css";
import './styles/Faq.module.scss';
import "./styles/Services.scss"
import "./styles/About.scss";
import "./styles/Contact.scss";
import "./styles/FaqCategoryItemComponent.scss"
import "./styles/FaqQuestionList.scss";
import "./styles/Home.scss";
import "./styles/EmploymentGrid.scss";
import "./styles/JobDescription.scss";
import "./styles/GenericMobile.scss";
import {
    Checkout,
    Orders, 
    OrderDetailsContainer, 
    PurchaseOrder,  
    DesignTask
    } from "sacatelle-orders";
import { Products } from "sacatelle-products"
import {   
    ResetPassword,   
    registerRoute,
    loginRoute,
    logoutRoute,
    LoginContainer,
    LogoutContainer,
    RegisterContainer,
    VerificationRequiredContainer,
    createNewPasswordRoute,
    verificationRoute,
    ClientAccount,
} from "sacatelle-login-client"
import { Home } from "./landing-pages/components/home/Home";
import { About } from "./landing-pages/components/about/About";
import { Faq } from "./landing-pages/components/faq/Faq";
import { Services } from "./landing-pages/components/our-services/Services";
import { Contact } from "./landing-pages/components/contact/Contact";
import { JobOpportunities } from "./landing-pages/components/job-oppurtunities/JobOpportunities";
import { JobDescription } from "./landing-pages/components/job-oppurtunities/JobDescription";
import { Landing } from "./landing-pages/components/landing/Landing";
import {CartContext} from "sacatelle-cart"
const App:FC = () =>{
    const [email, setEmail] = useState({address: ""})
    let displayName = App.name;
    let destination = process.env.REACT_APP_GRAPHQL;
    var role = "client";
    return (
            <Layout>
                <Routes>
                <Route
                    path={loginRoute}
                    element={<LoginContainer
                    onSuccessRedirect="/products"
                    role={role}/>}
                />
                    <Route path={logoutRoute}
                    element={
                        <LogoutContainer role={role} loginRoute={loginRoute} />}
                />
                <Route path={registerRoute}
                    element={
                        <RegisterContainer
                            role={role}
                        />}
                />
                <Route path={createNewPasswordRoute}
                    element={
                        <ResetPassword
                            title={"Create New Password"}
                        />}
                />
                <Route path={verificationRoute}
                    element={<VerificationRequiredContainer />}
                />
                    <Route path="/" element={<Home/>} />
                    <Route path="/about" element={<About/>} />
                    <Route path="/faq" element={<Faq/>} />
                    <Route
                        path="/products"
                        element={
                            <Products
                                cartContext={CartContext}
                                role={"client"}
                                blobStorageUrl={process.env.REACT_APP_BLOB + "products/"}
                            />
                        }
                    />
                    <Route path="/services" element={<Services/>} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/jobopportunities" element={<JobOpportunities/>}/>
                    <Route path="/jobdescription/:id" element={<JobDescription/>} />
                    <Route path="/sac/corporate-swag" element={<Landing/>} />
                    <Route path="/sac/custom-swag" element={<Landing/>} />
                    <Route path="/sac/custom-apparel" element={<Landing/>} />
                    <Route path="/sac/custom-bags" element={<Landing/>} />
                    <Route path="/sac/custom-packaging" element={<Landing/>} />
                    <Route path="/sac/custom-merch" element={<Landing/>} />
                    <Route path="/sac/swag-kits" element={<Landing/>} />
                    <Route path="/sac/merch-shops" element={<Landing/>} />

                    <Route path="/checkout/order/:orderNumber" element={<Checkout blobUrl="https://sacatelleapidevstorage.blob.core.windows.net/"/>} />
                    <Route path='/purchase-order' element={<PurchaseOrder isAdmin={false}/>}/>
                    <Route path="/orders/order/:orderNumber" element={<OrderDetailsContainer graphQlUrl={process.env.REACT_APP_GRAPHQL}  />}/>
                    <Route path='/orders' element={<Orders blobStorageUrl={process.env.REACT_APP_BLOB} />}/>
                    <Route path='/requests/request/:requestNumber' element={<DesignTask role={"client"} blobStorageUrl={process.env.REACT_APP_BLOB}/>}/>
                    <Route path='/requests' element={<Orders isActive={true} blobStorageUrl={process.env.REACT_APP_BLOB} />}/>
                    <Route path='/account' element={<ClientAccount/>}/>
                </Routes>
            </Layout>
        );
    }

export default App
