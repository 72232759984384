import React, {Component} from 'react';
import styles from "../styles/CardList.module.scss"
import design from "../../../assets/images/swag/designSmall.png"
import fulfillment from "../../../assets/images/swag/fulfillmentSmall.png"
import inventory from "../../../assets/images/swag/inventoryControlSmall.png"
import swag from "../../../assets/images/swag/swagShopsSmall.png"
import platform from "../../../assets/images/swag/platformSmall.png"
import integration from "../../../assets/images/swag/integrationsSmall.png"
import Card from '../../shared/Card';


const SwagCardList = ({isMobile}) => {

    const swagItems = [
        {
            img: design,
            title: "Design",
            intro: "Our creative design team will design you something incredible, incorporate brand identity, and create swag to impress."
        }, {
            img: fulfillment,
            title: "Fulfillment",
            intro: "From assembling kits and packages, to sending out your customer orders, our fulfillment centers across the U.S.\n" +
                "fulfill orders A to Z."
        }, {
            img: inventory,
            title: "Inventory Control",
            intro: "Ship your products to our warehouse. View/manage inventory, and create orders and ship directly from your Account."
        }, {
            img: swag,
            title: "Swag Shops",
            intro: "We build online stores, merch shops, and e-commerce solutions for your brand. Our team\n" +
                "of experts will launch your shop\n" +
                "to the moon. "
        }, {
            img: platform,
            title: "Platform",
            intro: "Manage orders, send shipments,  and view inventory right from your Dashboard. "
        }, {
            img: integration,
            title: "Integration",
            intro: "Our platform allows for\n" +
                "cross-system API integration\n" +
                "to help streamline  operations, and provide realtime order\n" +
                "data and information. "
        }
    ]

        return (
            <div className={isMobile ? styles.mobileCardListContainer : styles.cardLstContainer} style={{ margin : "0 auto"}}>
                {
                    swagItems.map((item, index) => {
                        return (
                            <div className={styles.centerCard}>
                                <Card descriptionWidth={"175px"} width="225px" height="275px" title={item.title} imageUrl={item.img} introText={item.intro}
                                      whiteBkg={false} whiteBackgroundStyleParagraph={false} isIcon={true} />
                            </div>
                        )
                    })
                }
            </div>
        );
    }

export default SwagCardList;
