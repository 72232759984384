import React, {Component} from 'react';
import styles from "../styles/ContainerWithTopicIndicator.module.scss"
import { IconWText } from '../atoms/IconWText';

interface ContainerWithTopicIndicatorProps{
    topicName: string;
    viewWidth?: number;
}

class ContainerWithTopicIndicator extends Component<ContainerWithTopicIndicatorProps,any> {

    render() {
        return (
            <div style={{ textAlign: this.props.viewWidth && this.props.viewWidth <= 920 ? "center" : "inherit" }}>
                <div>
                <IconWText textClassName={styles.breadCrumb} text={this.props.topicName} src={require("../../../assets/images/CheckboxSelectedMedium.png")}/>
                </div>
                {this.props.children}
                
            </div>

        );
    }
}

export default ContainerWithTopicIndicator;
