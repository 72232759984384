import React, { useState } from "react";
import { Diagnol } from "sacatelle-common";
import BG from "../../../../assets/images/about/bg-about.png";

const WhatWeCareAbout = () => {
  const [category, setCategory] = useState(2); // Initial category is 2 ("Growth")

  return (
    <div
      className="flex-row what-we-care-about-container"
      style={{ margin: "0 auto", width: 700 }}
    >
      <img src={BG} className="what-we-care-bg" />
      <div
        className="flex-column what-we-care-box"
        style={{ border: 0, width: 200, zIndex: 9999 }}
      >
        <div
          className="what-we-care-category"
          style={{
            borderBottom:
              category === 1 ? "1px solid #1dcad3" : "1px solid transparent",
          }}
          onClick={() => setCategory(1)}
        >
          <p>CREATIVITY</p>
        </div>
        <div
          className="what-we-care-category"
          style={{
            borderBottom:
              category === 2 ? "1px solid #1dcad3" : "1px solid transparent",
          }}
          onClick={() => setCategory(2)}
        >
          <p>GROWTH</p>
        </div>
        <div
          className="what-we-care-category"
          style={{
            borderBottom:
              category === 3 ? "1px solid #1dcad3" : "1px solid transparent",
            cursor: "pointer",
          }}
          onClick={() => setCategory(3)}
        >
          <p>CULTURE</p>
        </div>
      </div>
      <div className="what-we-care-box">
        <div className="what-we-care-title">
          <p>WHAT WE CARE ABOUT</p>
        </div>
        <div className="what-we-care-text">
          <Diagnol
            width={810}
            height={600}
            cutOutBackgroundColor={"transparent"}
          >
            <span style={{ display: category === 1 ? "block" : "none" }}>
              There is plenty of blank space out there, make something out of
              it. <br />
              <br />
              Creativity or a desire to be creative is at the basis of
              everything we do, as humans in general, and for us as a company.
              Our passion for creativity grew out of the massive success brands
              to have found with creative branding, encouraging not only the
              work we do but our very culture to encourage creative thinking.
            </span>
            <span style={{ display: category === 2 ? "block" : "none" }}>
              The universe is continually expanding, so are we.
              <br />
              <br /> We think we are pretty awesome, but that doesn't mean there
              isn't plenty of room for improvement. We are constantly reviewing
              and improving our systems to ensure the best possible experience
              for our clients and to make sure that everyone is happy - because
              that is what matters the most. Promoting from within, recognizing
              and developing alternative talents, and looking for talent from
              diverse back- grounds. These are core principles behind Sacatelle
              now and well into the future.
            </span>
            <span style={{ display: category === 3 ? "block" : "none" }}>
              Have fun...pretty simple.
              <br />
              <br />
              It’s easy to lose the battle of work vs life, but we are all about
              balance. A happy crew is a successful crew, and so we try and have
              as much fun as possible while still getting everything done. Ping
              pong, football, Basketball, and BBQs all boost morale plus they
              also tend to lead to some killer brainstorm sessions. We are all
              kids at heart and that is the beauty of entering the Sacatelle
              universe. You get to fulfill all of those childhood astronaut
              fantasies - plus get some work done.
            </span>
          </Diagnol>
        </div>
      </div>
    </div>
  );
};

export default WhatWeCareAbout;
