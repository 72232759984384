import React, {FC, useState} from "react";
import parse from 'html-react-parser';
import style from "./QuestionListItem.module.scss"
import {ChevronDown, ChevronUp} from "react-bootstrap-icons";

interface FaqQuestionListItemProps {
    id?: number,
    title?: string,
    description: string,
}


export const FaqQuestionListItem: FC<FaqQuestionListItemProps> = (props) => {
    const [isActive, setIsActive] = useState(false)
    return (
      <>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <span className={style.accordion_title}>{props.title && props.title}</span>
              { isActive ?
                  <ChevronUp size={20} onClick={() => setIsActive(!isActive)} />
                  :
                  <ChevronDown size={20} onClick={()=> setIsActive(!isActive)} />
              }
          </div>
          <div style={{margin: "0 0 20px", paddingRight: "20px", fontWeight: "400" , fontSize: "16px"}} className={isActive ? style.show : style.noShow} >{parse(props.description || "")}</div>
      </>


    )

}
