import React, { CSSProperties, FC } from "react"
import { Carousel, CarouselItem, CarouselProps } from "react-bootstrap"

interface Props extends CarouselProps{
    show?: number;
    style?: CSSProperties;
    children:any[];
    variant?: "dark" | undefined;
}
export const SacatelleSlider : FC<Props> = (props) => {

    var show = props.show ?? 1;
    var slideCount = props.children.length / show;
    var rows: any[] = [];

    for (var i = 0; i < slideCount; i++){
            rows.push(<CarouselItem key={i}>
                {
                    React.Children.map(props.children, (x2, i2) => {
                        if (i2 >= i*show && i2 < (i + 1)*show )
                            return <div style= {{display: "inline-block", marginBottom: "20px"}}>{x2}</div>
                    })
                }
            </CarouselItem>)
    }
    
    return <Carousel controls={show < 4} interval={null} style={{...props.style, overflow:"hidden"}}>
        {rows}
    </Carousel>;

}
