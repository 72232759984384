import React, {FC} from "react";
import {SearchListItem} from "./SearchListItem";
import {FaqCategory} from "./FaqCategory";
import style from "./searchListItem.module.scss"

interface SearchListProps{
    foundItems: FaqCategory[],
    questionSelectedHandler?: (id:number, questionSelected: string) => void;
    questionSelected?: string
    questions: any[]
    targetId: string
}

export const SearchList:FC<SearchListProps> = (props) =>{
        return (
            <div className={style.searchCenter}>
                <div className={style.searchDropdown}>
                    <div style={{height: "50px", color: "gray", padding: "20px"}}><span>Search results:</span></div>
                    {props.foundItems.map( (item, index) => {
                            return  <div key={index}>
                                <SearchListItem  questions={props.questions} targetId={props.targetId} questionItem={item} />
                            </div>
                    })}
                </div>
            </div>
        )

}
