import React from "react";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import RocketMan from "../../../../assets/images/about/RocketMan.png"
import { AboutSection } from "../../molecules/AboutSection";

const JoinCrew = () => {
  return (
    <AboutSection containerClassString={joinCrewContainerClasses}>
        <div style={{display: "flex", flexFlow: "row", flexWrap: "wrap", textAlign: "center", justifyContent: "center", marginTop: "-375px"}}>
            <img src={RocketMan} alt="Rocket Man"/>
            <div
                style={{
                    display: "flex",
                    flexDirection:"column",
                    width: "auto",
                    justifyContent: "center",

                }}
            >
                <span className="join-title">WANT TO JOIN OUR CREW?</span>
                    <Link to={"/jobopportunities"}>
                        <Button variant="primary" style={{textTransform: "uppercase"}}>explore opportunities</Button>
                    </Link>

            </div>
        </div>
    </AboutSection>
  );
};
const header = "Want to join our crew?";
const joinCrewContainerClasses = "header-line-around join-crew ";

export default JoinCrew;
