import React, { Component } from "react";
import { HomeBanner } from "./molecules/HomeBanner";
import "./styles/Home.scss";
import SwagSolutionSection from "./organisms/SwagSolutionSection";
import GrabFreeSampleSection from "./organisms/GrabFreeSampleSection";
import WeBuildSwagSection from "./organisms/WeBuildSwagSection";
import MerchShops, { MerchShopsMobile } from "./organisms/MerchShops";
import FollowOurJourney from "./organisms/FollowOurJourney";

interface HomeState {
  viewWidth: number;
}
export class Home extends Component<{}, HomeState> {
  constructor(props) {
    super(props);
    this.state = {
      viewWidth: 0,
    };
  }

  handleResize = () => {
    this.setState({
      viewWidth: window.innerWidth,
    });
  };
  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize.bind(this));

    
  }
  componentWillUnmount(){

  }
  render() {
    return (
      <div
        className={"home-container"}
        style={{ height: "80%", overflow: "hidden", marginTop:-65 }}
      >
        <div style={{ height: "100%", overflow: "hidden" }}>
          <HomeBanner />
          <div className="main-slogan">
            WE DESIGN IT, PRINT IT & SHIP IT
          </div>
          <WeBuildSwagSection viewWidth={this.state.viewWidth} />
          <SwagSolutionSection viewWidth={this.state.viewWidth} />
          {this.state.viewWidth > 920 ? <MerchShops /> : <MerchShopsMobile />}
          <GrabFreeSampleSection viewWidth={this.state.viewWidth} />
          <FollowOurJourney viewWidth={this.state.viewWidth}/>
        </div>
      </div>
    );
  }
}
//initial
