import React, { FC, ReactElement } from "react";


interface AboutSectionProps {
  containerClassString?: string | "";
  secondaryHeader?: string | undefined;
  header?: string;
  headerClassString?: string | "";
}
export const AboutSection: FC<AboutSectionProps> = (props) => {
  var classString = props.containerClassString;
  var headerCleasses = props.headerClassString;
  return (
    <div className={classString}>
      {props.header && <h2 className={headerCleasses}> {props.header} </h2>}
      {props.secondaryHeader !== undefined ? (
        <h6>
          {props.secondaryHeader}
        </h6>
      ) : (
        ""
      )}
      {props.children}
    </div>
  );
};
