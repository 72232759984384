import React, { FC, ReactElement } from "react";
import "./styles/GenericMobile.scss";
// import { H1TitleWithDiagonalBackground } from "../../atoms/H1TitleWithDiagonalBackground";
import { DiagonalTextColorBackground } from "sacatelle-common";
import { GetStartedButton } from "../home/molecules/GetStartedButton";

interface OurExtendedFamilySlideProps {
  title?: string;
  styledTitle?: ReactElement;
  defaultButton?: boolean;
  minHeight?: number;
  maxHeight?: number;
  paddingLeft?: number;
}

const GenericMobileContainer: FC<OurExtendedFamilySlideProps> = ({
  title,
  children,
  defaultButton,
  minHeight,
  maxHeight,
  styledTitle,
}) => (
  <div className="generic-mobile-container" style={{ minHeight }}>
    <div className="">
      <div
        style={{
          margin: "0 auto",
          marginTop: 0,
          marginBottom: 100,
          textAlign: "center",
        }}
      >
        {title && (
          <DiagonalTextColorBackground
            paddingLeft={14}
            paddingRight={14}
            marginLeft={10}
            noVerticalAlign={true}
            fontSize={24}
          >
            {title}
          </DiagonalTextColorBackground>
        )}
        {styledTitle}
      </div>
      <div
        style={{
          minHeight: "min-content",
          position: "relative",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        {children}
      </div>
      {defaultButton && (
        <div style={{ marginTop: -60 }}>
          <GetStartedButton />
        </div>
      )}
    </div>
  </div>
);

export default GenericMobileContainer;
