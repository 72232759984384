import React from "react";
import styles from "../styles/Merchshops.module.scss";
import MerchShopsImg from "../../../assets/images/merch-shops/merch-shops.png";
import ForwardSlashImg from "../../../assets/images/merch-shops/forwardSlash2.png";
import ForwardSlashImgWhite from "../../../assets/images/merch-shops/forwardSlash-white.png";
import { DiagonalTextColorBackground } from "sacatelle-common";

const MerchShops = () => (
  <div className={styles.container}>
    <div className={styles.forwardTitle}>
      <img src={ForwardSlashImgWhite} className={styles.img} /> MERCH SHOPS
    </div>
    <div className={`flex-row ${styles.subContainer}`}>
      <div
        className={`flex-column ${styles.leftSide}`}
        style={{ width: "100%", backgroundImage: `url(${MerchShopsImg})` }}
      />
      <div className={`flex-column ${styles.rightSide} `}>
        <div className={styles.title}>WE BUILD</div>
        <div className={`flex-row ${styles.titleAdvanced}`}>
          <DiagonalTextColorBackground
            noVerticalAlign={true}
            backgroundColor="#fff"
            paddingRight={15}
            paddingLeft={5}
            color="#101923"
            fontWeight={400}
          >
            MERCH
          </DiagonalTextColorBackground>
          <div style={{ marginLeft: 8 }}>SHOPS</div>
        </div>

        <div
          className={`flex-row ${styles.textAndIcon}`}
          style={{ marginLeft: 150 }}
        >
          <ForwardSlash />
          <div className={styles.textBox}>
            Custom designs will be ready for your review within 24 hours. Let's
            get started on some free designs.
          </div>
        </div>
        <div className={`flex-row ${styles.textAndIcon}`}>
          <ForwardSlash />
          <div className={styles.textBox}>
            360° E-Commerce Solution Empowering Brands Online Online Store
            Management
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MerchShops;

export const MerchShopsMobile = () => (
  <div className={`${styles.container} ${styles.containerMobile}`}>
    <div className={styles.subContainerMobile}>
      <div className={styles.titleMobile}>WE BUILD</div>
      <div className={`flex-row ${styles.titleAdvancedMobile}`}>
        <DiagonalTextColorBackground
          noVerticalAlign={true}
          backgroundColor="#fff"
          paddingRight={15}
          paddingLeft={5}
          color="#101923"
          fontWeight={400}
        >
          MERCH
        </DiagonalTextColorBackground>
        <div style={{ marginLeft: 8 }}>SHOPS</div>
      </div>
      <img src={MerchShopsImg} className={styles.imgMobile} />
      <div className="flex-column" style={{ justifyContent: "center" }}>
        <div className={`flex-row ${styles.textAndIconMobile}`}>
          <ForwardSlash />
          <div className={styles.textBox}>
            Custom designs will be ready for your review within 24 hours. Let's
            get started on some free designs.
          </div>
        </div>
        <div className={`flex-row ${styles.textAndIconMobile}`}>
          <ForwardSlash />
          <div className={styles.textBox}>
            360° E-Commerce Solution Empowering Brands Online Online Store
            Management
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ForwardSlash = () => (
  <img src={ForwardSlashImg} className={styles.forwardSlash} />
);
