import React, {useState} from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import "./JobDescription.scss";
import Parser from "html-react-parser";
import {ButtonWithHoverEffect, MessageSent, sendEmail} from "sacatelle-common";
import {ButtonArrowExpandable} from "./ButtonArrowExpandable";
import {useParams} from "react-router-dom";


let title = window.location.search.split("=")[1]

function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "/" + day + "/" + year;
}

export const JobDescription = (props) => {
    const {id} = useParams()
    function scrollToTestDiv() {

        const divElement = document.getElementById("divInterested");
        divElement?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
    const SEND_JOB_APPLICATION_EMAIL = gql`
        mutation sendApplicationEmail($to: String!, $message: String!, $attachments: [Attachments], $subject: String!  ){
            sendEmail(to: $to, message: $message, subject: $subject, attachments: $attachments)
        }
    `
    const [mutateSendEmail] = useMutation(SEND_JOB_APPLICATION_EMAIL)


    const getEmploymentOpportunities = gql`
  {
    employmentOpportunity(id: ${id}) {
      id
      location
      createdDate
      jobPosition
      jobOverview
      reportsTo
      qualification
      responsibilityAndDuties
    }
  }
`;

    const {data, loading, error} = useQuery(getEmploymentOpportunities);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [portfolio, setPortfolio] = useState("");
    const [tellus, setTellus] = useState("");
    const [base64, setBase64] = useState<{base64: any}[]>();
    const [fileData, setFileData] = useState<{
        name: string;
    }[]>();
    const [indicator, setIndicator] = useState<boolean>(false);

    async function onSubmit(e) {
        e.preventDefault();
        mutateSendEmail({
            variables: {
                to: "info@sacatelle.com",
                subject: "Job Application",
                message: `<div><h2>Applying For: ${data?.employmentOpportunity?.jobPosition}</h2>
                            <p><h4>Name:</h4>${name}</p><p><h4>Email:</h4> ${email}</p>
                            <p><h4>Portfolio Link:</h4> ${portfolio}</p>
                            <p><h4>Tell Us About Yourself:</h4>${tellus}</p></div>`,
                attachments:   base64
            }
        }).then((result) => {
            result.data.sendEmail === true
               ? setIndicator(true)
               : setIndicator(false);
        })
        setEmail("")
        setName("")
        setBase64([])
        setFileData([])
        setPortfolio("")
        setTellus("")
    }

    // ---- base64 handler ----

    const processFile = async (e: { [key: string]: any }) => {
        console.log('attach name', fileData)
        fileData?.map((item, index) => {
            console.log("name", item.name)
        })
        const file = e.target.files[0];
        const name = file.name;
        const type = file.type;
        const lastModifiedDate = file.lastModifiedDate;
        setFileData(fileData ? [...fileData, {name: name}]: [ {name: name}]);

        const newBase64 = await convertBase64(file);
        setBase64(base64 ? [...base64, {base64: newBase64}] : [{base64: newBase64}]);
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    return (

        <section className="job-description-container">
            <div className="divBackBtn">

                <ButtonArrowExpandable buttonValue={"Back"} buttonContainerClass={"jobs-description-back-button-container"} colorOfSvg={"#101923"}
                                       customClickHandler={() => window.location.href = `/jobopportunities`}/>
            </div>
            <div className="jobDescriptionTitleContainer">
                <div className={"titleBg"}>
                    <div className={"title"}>{data?.employmentOpportunity?.jobPosition}</div>
                </div>
            </div>
            <div className="desc-body">
                {data && data.employmentOpportunity && (
                    <div>
                        <div className="desc-top">
                            <div>
                                <div>
                                    <h4>LOCATION</h4>
                                    <p>{data.employmentOpportunity.location}</p>
                                </div>
                                <div>
                                    <h4>POSTED TIME</h4>
                                    <p>{`${getFormattedDate(
                                        new Date(data.employmentOpportunity.createdDate)
                                    )}`}</p>
                                </div>
                            </div>
                            {title === "description" &&
                            <ButtonWithHoverEffect height={"32px"} width={"130px"} id={"job-apply"} href={`/jobdescription/${id}?title=apply`}
                                                   title={"apply"} jumpToId={"application"}/>}
                        </div>
                        <div className="desc-middle">
                            <div>
                                <div>
                                    <h4>JOB OVERVIEW</h4>
                                    <p>{data.employmentOpportunity.jobOverview}</p>
                                </div>
                                <div>
                                    <h4>RESPONSIBILITIES & DUTIES</h4>
                                    <p>
                                        {Parser(data.employmentOpportunity.responsibilityAndDuties)}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h4>REPORTS TO</h4>
                                    <p>{data.employmentOpportunity.reportsTo}</p>
                                </div>
                                <div>
                                    <h4>QUALIFICATION</h4>
                                    <p>{Parser(data.employmentOpportunity.qualification)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* <div id="divInterested" style={{paddingTop:80}}>Stop Here</div> */}

            {title === "apply" &&

            <div id="application">
                <div className={"titleContainerWide"}>
                    <div className={"titleBgWide"}>
                        <div className={"title"}>Interested?</div>
                    </div>
                </div>
                <div className={"titleContainerWideMarginLeft"}>
                    <div className={"titleBgWide"}>
                        <div className={"title"}>Awesome!</div>
                    </div>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="divInterestedForm">
                        <div className="divInterestedFormLeft">
                            <div className="job-inputs">
                                <div>
                                    <h2>Name</h2>
                                    <input
                                        required
                                        id="name"
                                        placeholder=""
                                        type="text"
                                        name="name"
                                        onChange={(e: any) => setName(e.target.value)}
                                        value={name}
                                    />
                                </div>
                                <div>
                                    <h2>Email</h2>
                                    <input
                                        required
                                        id="email"
                                        placeholder="Email"
                                        type="text"
                                        name="email"
                                        onChange={(e: any) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </div>
                                <div>
                                    <h2>Portfolio URL</h2>
                                    <input
                                        id="portfolio"
                                        placeholder=""
                                        type="text"
                                        name="portfolio"
                                        onChange={(e: any) => setPortfolio(e.target.value)}
                                        value={portfolio}
                                    />
                                </div>
                            </div>
                            <div>
                                <h2>Attach your resume / cover letter:<span style={{fontSize: "10px", color: "gray", fontStyle: "italic", marginLeft: "10px"}}>(pdf or word doc)</span> </h2>
                                <div className="file-upload" style={{marginBottom: "10px"}}>
                                    <input type="file" onChange={(e) => processFile(e)} required multiple/>
                                    {/* <br></br> */}
                                    {/* <img src={base64} height="200px"/> */}
                                </div>
                                {
                                    fileData?.map((item, index) => {
                                        return <><span style={{fontSize: "12px", color: "gray", fontStyle: "italic"}}>{item.name}</span><br/></>
                                    })
                                }
                            </div>
                        </div>
                        <div className="divInterestedFormRight">
                            <h2>TELL US ABOUT YOURSELF</h2>
                            <textarea
                                onChange={(e: any) => setTellus(e.target.value)}
                                value={tellus}
                           />
                        </div>

                    </div>
                    <div className="submit-container">
                    <div className="flex-column">
                        <ButtonWithHoverEffect height={"32px"} width={"130px"} id={"job-submit"} title={"submit"}
                                              type="submit"  buttonHandler={()=>{}} />
                    <div style={{ marginTop: -50, marginLeft: 20, marginBottom: 50 }}>{ indicator && <MessageSent />}
                    </div>
                      </div>
                        </div>
                </form>
            </div>
            }
        </section>

    );
};
