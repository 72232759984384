import React from "react";
import Universe from "../../../../assets/images/about/universe.png";
import {Diagnol} from "sacatelle-common";

export const OurMission = () => (
    <div style={{margin: "0 auto"}}>
        <div
            style={{
                justifyContent: "center",
                padding: 0,
                marginBottom: '10px',
                position: "relative",
                top: "30px",
                height: "fit-content",
                overflow: "hidden",
            }}
            className=" about-text"
        >
            <div style={{width: "1160px", margin: "0 auto", transform: "translateX(-15%)"}}>
                <div>
                    <div style={{position: "absolute", top: "83px", left: "-30px"}}>
                        <div
                            className="our-mission-title  aUniverse"
                        >
                            <p>A UNIVERSE</p>
                        </div>
                        <div
                            className="our-mission-title ofDifference"
                        >
                            <p>OF DIFFERENCE</p>
                        </div>
                    </div>
                    <div
                        style={{
                            marginRight: "2%",
                            backgroundImage: `url(${Universe})`,
                            width: 600,
                            height: 500,
                            position: "absolute",
                            transform: "translateX(200px)",
                            top: '1px',
                            zIndex: 0,
                        }}
                    />
                </div>

                <div style={{position: "relative", left: "270px", top: "50px"}}>
                    <Diagnol width={750} height={700} cutOutBackgroundColor="transparent" isRight={true} isLeft={true}>
                        <div
                            style={{
                                fontSize: 28,
                                fontWeight: 500,
                                marginBottom: 6,
                            }}
                        >
                            OUR MISSION
                        </div>

                        <div style={{fontWeight: 300, lineHeight: 2, width: '1000px'}}>
          From design through delivery, our mission is to exceed all
          expectations. In 2015 Sacatelle emerged on the promotional marketing
          scene and quickly found incredible success with brands such as the New
          York Times, Postmates, and Red Bull. Through creative design, pushing
          the industry’s conventional limitations, Sacatelle was able to set
          themselves apart from the competition. The company's vision is to
          reinvent consumer marketing by testing the creative boundaries of
          brand engagement, all while having a blast doing it.{" "}
        </div>

                    </Diagnol>
                </div>
            </div>

        </div>
    </div>

);
