import React, {FC, useEffect, useState} from "react";
import {SacatelleSlider} from "./SacatelleSlider";
import { EmployeeAbout } from "../atoms/EmployeeAbout";

interface IProps {
  width?: number;
}

export const EmployeeSlider: FC<IProps> = (props) => {

  const [activeName, setActiveName] = useState("")
  const [items, setItems] = useState(2)
  const handleToUpdateActiveName = (aName) => {
     setActiveName(aName)
  };

  useEffect(() =>{
    if(props.width! < 768){
      setItems(1)
    }
    if(props.width! > 767 && props.width! < 960){
      setItems(2)
    }
    if(props.width! > 960 && props.width! < 1071){
      setItems(3)
    }
    if(props.width! > 1070){
      setItems(4)
    }

  }, [props.width])


    return (
      <SacatelleSlider show={items} >
        {employeeInfoList.map((infoList, index) => (
          <li
            key={index}
            className="crew-column"

            style={{
              userSelect: "none",
              marginLeft:
                props.width && props.width > 767 ? "inherit" : -10,
            }}
          >
            <EmployeeAbout
              info={infoList[0]}
              index={index}
              isActive={infoList[0].name === activeName}
              handleToUpdateActiveName={handleToUpdateActiveName}
              width={props.width}
            />
            <EmployeeAbout
              info={infoList[1]}
              index={index}
              isActive={infoList[1].name === activeName}
              handleToUpdateActiveName={handleToUpdateActiveName}
              width={props.width}
            />
          </li>
        ))}
      </SacatelleSlider>
    );

}

var employeeInfoList = [
  [
    {
      image: "About_Ikey.png",
      image_h: "About_Ikey_Hover.png",
      name: "IKEY",
      role: "Chief Creative Officer",
      boxColor: "#AB661F",
      data: {
        name: "IKEY Bensimhon",
        desc: `
Ikey Bensimhon, our fearless leader - he's a roll up the sleeves, dig right in kind of guy that oversees Sacatelle’s global business. He’s a big believer in the power of creativity to drive business growth and ultimately inspire human behavior. His vision and passion are the jet fuel of our success.
        `,
        linkedin: "https://www.linkedin.com/company/spacex",
      },
    },
    {
      image: "About_Isaac.png",
      image_h: "About_Isaac_Hover.png",
      name: "Isaac",
      role: "Executive Director ",
      boxColor: "#429FA8",
      data: {
        name: "Isaac Schwerd",
        desc: `
Isaac is the brain behind our brawn, he eats excel sheets for breakfast and without him, our ship would go nowhere. A born and bred New Yorker, Issac usually has a coffee in hand and can tell you the best pizza places from coast to coast. Anything business-related, he’s our man!
        `,
        linkedin: "https://www.linkedin.com/company/spacex",
      },
    },
  ],
  [
    {
      image: "About_Jasmine.png",
      image_h: "About_Jasmine_Hover.png",
      name: "Jasmine",
      role: "Controller",
      boxColor: "#A7A8A9",
      data: {
        name: "Jasmine Verduzco",
        desc: `
Jasmine heads our finance department, so she is all business, unless you have puppies then it's all puppies. The size of her heart is hard to match, rescuing animals and taking care of our rowdy crew, she is simply the best.
        `,
        linkedin: "https://www.linkedin.com/company/spacex",
      },
    },
    {
      image: "About_Leslie.png",
      image_h: "About_Leslie_Hover.png",
      name: "Leslie",
      role: "Head of Design - USA",
      boxColor: "#AB661F",
      data: {
        name: "Leslie Meier",
        desc: `
Leslie is the head of our in-house design department, and her designs are simply out of this world. Soft-spoken but heard loud and clear, her designs are cutting edge with robotic-like precision. Originally from Germany, Leslie arrived just before take-off and just in time - for us.
        `,
        linkedin: "https://www.linkedin.com/company/spacex",
      },
    },
  ],
  [
    {
      image: "About_Ami.png",
      image_h: "About_Ami_Hover.png",
      name: "Ami",
      role: "Account Manager",
      boxColor: "#429FA8",
      data: {
        name: "Ami Bensimhon",
        desc: `
Ami’s drive is only matched by his energy - find him in the office early or late, maybe he’s still on New York time? He knows what's cool and you can tell by his car, but don't let the flash fool you, he walks the walk and is all business.
        `,
        linkedin: "https://www.linkedin.com/company/spacex",
      },
    },
    {
      image: "About_Andre.png",
      image_h: "About_Andre_Hover.png",
      name: "Andre",
      role: "VP of Sales",
      boxColor: "#A7A8A9",
      data: {
        name: "Andre Neves",
        desc: `
Andre is the captain of our ship - without him, we might be lost aimlessly exploring the universe. Though you will usually find him in the office, his Brazilian roots call him to the beach whenever possible, surfing, cycling, or making the best BBQ you’ve ever had!
        `,
        linkedin: "https://www.linkedin.com/company/spacex",
      },
    },
  ],
  [
    {
      image: "About_Karina.png",
      image_h: "About_Karina_Hover.png",
      name: "Karina",
      role: "Business Operations Manager",
      boxColor: "#AB661F",
      data: {
        name: "Karina Miller",
        desc: `
Karina takes care of our business operations, she's got the keys and helps keep us on course. She is full of hidden talents and never backs down from a challenge. Her competitive energy and tenacity make her a formidable opponent or a valued ally - luckily she's on our side.
        `,
        linkedin: "https://www.linkedin.com/company/spacex",
      },
    },
    {
      image: "About_Collin.png",
      image_h: "About_Collin_Hover.png",
      name: "Collin",
      role: "Creative Director",
      boxColor: "#429FA8",
      data: {
        name: "Collin Spratlin",
        desc: `
Collin is a salesperson with the heart of a creative, the crazier the better. Whether skateboarding, cycling, or shooting photos, he is always on the move and keeping a keen eye open. Every ship needs a renegade...
        `,
        linkedin: "https://www.linkedin.com/company/spacex",
      },
    },
  ],
  // page 2

];
