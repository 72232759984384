import React from "react";
import { Icon, IconProps } from "../../shared/Icon";

interface IconWTextProps extends IconProps{
    spacing?: number;
    text: string;
    textClassName?: string;
    IconOnRight?:boolean;
}
export const IconWText = (props: IconWTextProps) => {
    return <span>
        {!props.IconOnRight && 
        <span style={{ display:"inline-block", marginRight: props.spacing ? props.spacing : 5}}>
        <Icon src={props.src}/>
        </span>}
        <span className={props.textClassName}>
            {props.text}
        </span>
        {props.IconOnRight && 
        <span style={{ display:"inline-block", marginLeft: props.spacing ? props.spacing : 5}}>
            <Icon src={props.src} />
        </span>}
    </span>
}