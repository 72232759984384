import React from "react"
import { Button } from "./Button";

interface ButtonTransaparentProps {
    isUppercase?: boolean | false;
    text: string;
    outlineColor?: string;
}
export const ButtonTransaparent = (props:ButtonTransaparentProps) => {
    var outlineColor = props.outlineColor ?? "white";
    return <Button
     style={{
         backgroundColor: "transparent",
         color: outlineColor,
         lineHeight: "38px",
         padding: "12px 30px",
         border: "1px solid " + outlineColor,
         fontSize:10,
         letterSpacing:2.4,
         fontWeight:500,
     }}
     text={props.text} />
}
