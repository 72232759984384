import React, {FC} from "react";
import {FaqDetail} from "./FaqDetail";
import {FaqQuestionListItem} from "./FaqQuestionListItem";
import ".//FaqQuestionList.scss";

interface FaqQuestions {
    questions: FaqDetail[],
    questionSelected: string,
}

export const FAQQuestionsList: FC<FaqQuestions> = (props) => {
    let found: any[] = []
    if (props.questionSelected !== "") {
        found = props.questions.filter(item => item.title === props.questionSelected)
    } else {
        found = [...props.questions]
    }
    return (
        <div className="questions-wrapper">
            <div>

                    {
                        found.map((item, index) =>
                            <FaqQuestionListItem
                                id={item.id}
                                key={index}
                                title={item.title}
                                description={item.description}
                               />
                        )}


            </div>
        </div>
    )

}


