import React, {Component} from 'react';
import styles from "../styles/CTAColumn.module.scss"
import { GetStartedButton } from './GetStartedButton';

interface CallToActionColumnProps{
    title: string;
}

class CallToActionColumn extends Component<CallToActionColumnProps> {
    render() {
        return (
            <div className={styles.ctaContainerWrapper}>
                <div className={styles.ctaContainer}>
                    <div><h1 className={styles.title}>{this.props.title}</h1></div>
                    <div><p className={styles.content}>From product manufacturing to distribution, we have you covered.
                        We make it easy so that your team can focus on what matters. Designs ready for you to review within 24 hours.</p>

                        <p className={styles.content}> Shipping U.S. and International</p></div>
                    <div style={{ marginTop: -80 }}>
                        <GetStartedButton/>
                    </div>
                </div>
            </div>

        );
    }
}

export default CallToActionColumn;
