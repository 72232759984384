import React from "react";
import { Link } from "react-router-dom"
import styles from "./Footer.module.css";

const MissingAnything = () =>  <>
                <h4 className={styles.title}>Missing anything?</h4>
                <ul>
                    {/* <li className={styles.link}><Link to="/projects">Our Projects</Link></li>
                    <li className={styles.link}><Link to="/blog">Blog</Link></li> */}
                    <li className={styles.link}><Link to="/faq">FAQ</Link></li>
                    <li className={styles.link}><Link to="/contact">Contact us</Link></li>
                    <li className={styles.link}><Link to="/jobopportunities">Join the team</Link></li>
                </ul>
            </>

export default MissingAnything;