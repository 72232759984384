import React from "react";
import { Container } from "reactstrap";
import { NavMenu } from "sacatelle-common";
import {CartProvider as Cart, CartButtonIcon} from "sacatelle-cart";
import styles from "./Layout.module.scss";
import { Footer } from "../landing-pages/components/footer/Footer";

export const Layout = ({ children }) => {
  return(
  <Cart blobUrl={process.env.REACT_APP_BLOB} >
    <div id="top" style={{ minWidth: 320, overflow: "hidden" }}>
        <NavMenu
            numOfRightItems={1}
          role={"client"}
          listOfRightLinks={links.right}
          listOfCenterLinks={links.center}
          featureButton={<CartButtonIcon />}
        />
        <div className="layout-divider" />
        <Container fluid className={styles.container}>
          {children}
        </Container>
      {window.location.pathname.split('/')[1] !== "products" &&
        <Footer />
      }
    </div>
  </Cart>
);
}

const links = {
  center: [

    {
      to: "/products",
      text: "Shop",
    },
    {
      to: "/about",
      text: "About",
    },
    {
      to: "/services",
      text: "Services",
    },
    {
      to: "/contact",
      text: "Contact",
    },
  ],
  right: [
    {
      icon: <CartButtonIcon />,
    }
  ],
};
