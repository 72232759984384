import React, { useState } from "react";
import "./Contact.scss";
import { LocationMap } from "./LocationMap";
import { ThankYou } from "./ThankYou";
import { ModalSacatelle } from "sacatelle-common";
import { CenterTitle } from "./CenterTitle";
import forwardSlash from "../../assets/images/contact/forwardSlash.svg";
import style from "./Contact.module.scss"
import CompactForm from "../shared/CompactForm";

export const Contact = (props) => {
  const [show, setShow] = useState(false)
  const size = {width: window.innerWidth, height: window.innerHeight};

  return (
      <div  id="contact" className="flex-column">
        <div style={{ background: "#F3F3F4", paddingTop: 60 }}>
          <div
            className="title-left offset-left"
            style={{
              marginLeft: 100,
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: 3,
              paddingTop: 0,
            }}
          >
            <img
              src={forwardSlash}
              style={{ width: 30, marginRight: 20, marginTop: -5 }}
            />{" "}
            GET IN TOUCH
          </div>
          <div
            className="getInTouchContainer flex-row"
            style={{}}
          >
            <div
            >
              <CenterTitle mobile={false} />
              <ContactInfo mobile={false} />
            </div>
            <div >
              <CompactForm />
            </div>
          </div>
        </div>
      

      {!props.noMap && (
        <>
          <div
            className="title-left offset-left"
            style={{
              marginLeft: 100,
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: 3,
              paddingTop: 0,
              marginTop: 60,
            }}
          >
            <img
              src={forwardSlash}
              style={{
                width: 30,
                marginRight: size.width && size.width > 978 ? 20 : 3,
                marginLeft: -20,
              }}
            />{" "}
            <span className={style.subHeader}>WHERE TO FIND US</span>
          </div>
          <LocationMap />
        </>
      )}
        <ModalSacatelle 
         show={show}
         onHide={() => setShow(false)}>
        <ThankYou />
      </ModalSacatelle>
    </div>

  );
};

const ContactInfo = ({ mobile }) => (
  <div>
    <div
      className={`flex-column ${
        "contact-info-box"
      }`}
      style={{ paddingLeft: 82 }}
    >
      <div className="contact-subtitle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" />
        </svg>{" "}
        CALL US
      </div>
      <div className="contact-bold-text">(310) 967-7753</div>
      <div className="contact-small-text">Mon - Fri, 9am - 7pm, ET</div>
    </div>
    <div
      className={`flex-column ${
        "contact-info-box"
      }`}
      style={{ paddingLeft: 82 }}
    >
      <div className="contact-subtitle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style={{ marginRight: 3 }}
        >
          <path d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z" />
        </svg>{" "}
        SEND AN EMAIL
      </div>
      <div className="contact-bold-text">sales@sacatelle.com</div>
      <div className="contact-small-text">Replies within 2 hours</div>
    </div>
  </div>
);
