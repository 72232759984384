import React from "react";
import styles from "./Footer.module.css";
import { SocialMedia } from "./SocialMedia";
import { Subscribe } from "./Subscribe";
import MissingAnything from "./MissingAnything";
import { CopyRight } from "./CopyRight";

export const Footer = () =>
		<section className={styles.container}>
				<div className={styles.innerFooter}>
					<div className="flex-column">
						<div className={styles.socials}>
							<SocialMedia />
						</div>
					</div>
					<div className="flex-column">
						<Subscribe />
					</div>
					<div className="flex-column">
						<div className={styles.right}>
							<MissingAnything />
						</div>
					</div>
				</div>
			<div className={styles.copyright}>
				<CopyRight />
			</div>
		</section>

