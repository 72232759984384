import React, {Component} from "react";
import  "./ButtonArrowExpandable.scss";



interface ButtonArrowExpandableProps {
  buttonValue: string;
  colorOfSvg?: string;
  buttonClass?: string;
  buttonContainerClass?: string;
  buttonImageClass?: string;
  modalName?: string;
  customClickHandler: (e:any) => void

}
interface ButtonWithImageState {
  isButtonActive: string
}
export class ButtonArrowExpandable extends Component<ButtonArrowExpandableProps, any> {
constructor(props) {
  super(props);
  this.state={
    isButtonActive: "",
  }
}
   onClickHandler = (e) => {
    e.preventDefault();

if(this.state.isButtonActive === "active"){
    this.setState({isButtonActive: ""})
    this.props.customClickHandler("")
}
if(this.state.isButtonActive === "" ){
    this.setState({isButtonActive: "active"})
    this.props.customClickHandler("active")
}

  };

  render (){
    return (
        <div className={this.props.buttonContainerClass || ` buttonContainer  ${this.state.isButtonActive}`} >
          <div>
            <button style={{backgroundColor: "transparent"}}className={this.props.buttonClass || "textButton"}
                    onClick={  (e) => this.onClickHandler(e)}>
                {this.props.buttonValue}
              <div className="textButtonImage">
                  <svg width="16" height="10" viewBox="0 0 16 10" fill={ this.props.colorOfSvg ||"none"} xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.2635 1.39157L7.66912 9.22369L1.07471 1.39156L14.2635 1.39157Z" stroke="#101923"/>
                  </svg>
              </div>
            </button>
          </div>
        </div>
    )
  }
}

