import React from "react";
import styles from "../styles/FollowOurJourney.module.scss";
import {DiagonalTextColorBackground} from "sacatelle-common";
import One from "../../../assets/images/our_work_grid/bottles.jpg";
import Two from "../../../assets/images/our_work_grid/Backpacks.jpg";
import Three from "../../../assets/images/our_work_grid/Drinkware.jpg";
import Four from "../../../assets/images/our_work_grid/Mix.jpg";
import Five from "../../../assets/images/our_work_grid/Footwear.jpg";
import Six from "../../../assets/images/our_work_grid/Headware.jpg";
import Seven from "../../../assets/images/our_work_grid/Mix_007.jpg";
import Eight from "../../../assets/images/our_work_grid/Packaging_022.2.jpg";
import Nine from "../../../assets/images/our_work_grid/T-shirt_001.jpg";
import Ten from "../../../assets/images/our_work_grid/Totes_001.jpg";
import { GetStartedButton } from "../molecules/GetStartedButton";
// Desktop component:

const  GridImages = (props: {src: string, style}) => {
    return <div style={props.style}>
        <img src={props.src} style={{ width: "100%",  clipPath: "polygon(30% 0," +
                " 100% 0, 70%" +
                " 100%, 0 100%)"}}/>
    </div>;
}

const FollowOurJourney = ({viewWidth}) => (
    <>
        {viewWidth > 978 ? (
            <div>
                <div className={styles.imgContainerTop} style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                    height: "fit-content",
                    marginBottom: "5%",
                    marginTop: "100px",
                    transform: "scale(1.1)"
                }}>
                    <GridImages style={{transform: "translateX(2%)"}} src={One}/>
                    <GridImages style={{transform: "translateX(-20%)"}} src={Two}/>
                    <GridImages style={{transform: "translateX(-43%)"}} src={Three}/>
                    <GridImages style={{transform: "translateX(-65%)"}} src={Ten}/>
                    <div className={styles.textBox}>
                        <div className={`flex-row ${styles.title}`}>
                            <div style={{marginTop: 1, marginRight: 15}}>OUR</div>
                            {" "}
                            <DiagonalTextColorBackground paddingRight={16} paddingLeft={6}>
                                WORK
                            </DiagonalTextColorBackground>
                        </div>
                        <div className={styles.subTitle}>FOLLOW OUR JOURNEY</div>
                        <div style={{textAlign: "center", marginTop: "-20px"}}>
                            <GetStartedButton/>
                        </div>
                    </div>

                </div>
                <div  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                    height: "fit-content",
                    marginBottom: "5%",
                    transform: "scale(1.3)"

                }}>
                    <GridImages style={{transform: "translateX(42%)"}} src={Four}/>
                    <GridImages style={{transform: "translateX(18%)"}} src={Five}/>
                    <GridImages style={{transform: "translateX(-6%)"}} src={Six}/>
                    <GridImages style={{transform: "translateX(-29%)"}} src={Seven}/>
                    <GridImages style={{transform: "translateX(-51%)"}} src={Eight}/>
                    <GridImages style={{transform: "translateX(-75%)"}} src={Nine}/>
                </div>
            </div>
        ) : (
            <div className={styles.container}>
                <div className={styles.textBoxMobile}>
                    {/* <img src={GoogleHats} style={{ width: "100%" }} /> */}
                    <div className={`flex-row ${styles.title}`}>
                        <div style={{marginTop: 1, marginRight: 15}}>OUR</div>
                        {" "}
                        <DiagonalTextColorBackground paddingRight={16} paddingLeft={6}>
                            WORK
                        </DiagonalTextColorBackground>
                    </div>
                    <div className={styles.subTitle}>FOLLOW OUR JOURNEY</div>
                    <div style={{textAlign: "center"}}>
                        <GetStartedButton/>
                    </div>
                </div>
                <div className={styles.imgContainerMobile}>
                    <div  style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr ",
                        height: "fit-content",
                        marginBottom: "5%",
                        transform: "scale(1.3)"

                    }}>
                        <GridImages style={{transform: "translateX(20%)"}} src={Six}/>
                        <GridImages style={{transform: "translateX(-2%)"}} src={Seven}/>
                        <GridImages style={{transform: "translateX(-25%)"}} src={Eight}/>
                        <GridImages style={{transform: "translateX(-36%)"}} src={Nine}/>
                    </div>
                </div>
            </div>
        )}
    </>
);

export default FollowOurJourney;
