import React, {useEffect} from "react";
import {EmploymentGrid} from "./EmploymentGrid";
import styles from "./JobOpportunities.module.scss";
import {gql, useQuery} from "@apollo/client";


export const JobOpportunities = () => {
    const getEmployementOpportunities = gql`
        {
            employmentOpportunities {
                id
                location
                jobPosition
                createdDate
            }
        }
    `;

    useEffect(()=>{
        window.location.href="/jobopportunities/#jobs"
    },[])

    const {data} = useQuery(getEmployementOpportunities);

  return (
          <div id="jobs" className={styles.jobPageContainer} style={{ marginTop: "40px", scrollMarginTop: "56px"}}>
              <section className={styles.container}>
                  <div className={styles.titleContainer} ><div className={styles.titleBg}><div className={styles.title}> Hi! Nice To </div></div></div>
                  <div className={styles.titleContainer} ><div className={styles.titleBg}><div className={styles.title}> Meet You </div></div></div>
                  <div className={styles.gridContainer}>
                      <EmploymentGrid data={data} />
                  </div>
              </section>
          </div>
  )
}
