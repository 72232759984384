import React from "react"
import { Diagnol } from "sacatelle-common"
import { Image } from "sacatelle-common"
import { Link } from "react-router-dom";
import { ButtonTransaparent } from "../../shared/TransaparentButton";

interface HomeBannerItemProps {
    title:string;
    srcString: string;
    width:number | string ;
    height:number;
    isRight?:boolean;
    isLeft?:boolean;
    index: number;
    containerClassName?: string;
    backgroundColor: string;
    selectedIndex?: number | undefined;
    hoverCallback: (i:number | undefined) => void;
}
interface HomeBannerItemState{
    isActive: boolean;
}
export class HomeBannerItem extends React.Component<HomeBannerItemProps,{}> {
    constructor(props){
        super(props)
    }
    onItemMouseOver(){
        this.props.hoverCallback(this.props.index);
        
    }
    onItemMouseOut(e) {
        var item = $(".home-banner-item");
        
        if (e.pageY > 57 && item && e.pageY < this.props.height + 57) {
            return;
        }
        else
            this.props.hoverCallback(undefined);
    }
    calculateImageWidth(selectedIndex: number | undefined, index:number){
        return selectedIndex != undefined && selectedIndex == index ? "50%" : 
        selectedIndex == undefined ? "100%" :
        "200%";
    }
    calculateScale(selectedIndex: number | undefined, index:number){
        if (selectedIndex == 0 && selectedIndex != index && index == 3)
            return "scale(4.3)";
        if (index == 0)
            return selectedIndex != undefined && selectedIndex != index ? "scale(1.5)" : "scale(1.5)";
        else 
            return selectedIndex != undefined && selectedIndex != index ? "scale(1.5)" : "scale(2)";
    }
    render(){
        return <div 
        onMouseEnter={this.onItemMouseOver.bind(this)}
        onMouseOut={this.onItemMouseOut.bind(this)}
        style={{
            display:"inline-block", 
            backgroundColor:this.props.backgroundColor,
            zIndex: this.props.index, 
            position:"relative", 
            verticalAlign:"top",
            width:this.props.width, 
            height:this.props.height
        }}>
            <Diagnol 
                addLeft={this.props.index != 0}
                cutOutBackgroundColor={this.props.backgroundColor}
                isLeft={this.props.isLeft}
                isRight={this.props.isRight}   >
                <div 
                    style={{
                        height:"100%",
                        width:"100%",
                        backgroundColor: this.props.backgroundColor
                    }} >
                    <Image 
                        style={{
                            height:"95%", 
                            width:  this.calculateImageWidth(this.props.selectedIndex, this.props.index),
                            position:"absolute",
                            transform: this.calculateScale(this.props.selectedIndex, this.props.index),
                            top:  57,
                            left: 0,
                        }} 
                        src={require("../../../assets/images/home/" + this.props.srcString)}
                    />   
                    { this.props.selectedIndex == this.props.index ? <>
                        <Image 
                            style={{
                                height:"95%", 
                                width:  this.calculateImageWidth(this.props.selectedIndex, this.props.index),
                                position:"absolute",
                                transform: this.calculateScale(this.props.selectedIndex, this.props.index),
                                top:  57,
                                left: 0,
                            }} 
                            src={require("../../../assets/images/home/" + this.props.srcString.split(".")[0]+"Hover.svg")}
                            classes="home-banner-item-img-hover"
                        />   
                        <div style={{position:"absolute", width: "100%", bottom: "15%", marginLeft: this.props.index === 0 ? "15%" : 0, textAlign: "left"}}>
                            <h1 style={{color:"white"}}>{this.props.title}</h1>
                            <Link style={{textDecoration: "none"}} to={"/products?index="+this.props.index}>
                                <ButtonTransaparent text={"GET STARTED"} />
                            </Link>
                        </div></>
                         : ""
                    }

                </div>
            </Diagnol>
        </div>
    }
}
