import React, {Component} from "react";
import {Diagnol, DiagnolImage, DiagonalTextColorBackground, Image} from "sacatelle-common";
import { H1TitleWithDiagonalBackground } from "../shared/H1TitleWithDiagonalBackground";
import { MobileFlexibleView } from "../shared/MobileFlexibleView";

interface ServiceSlideProps {
  content: ContentData;
  pageIndex: number; //based on this we can determine left or right direction
}
interface ContentData {
  title: string;
  text: string;
  listItems: string[];
  listTitle: string;
  image: string;
}
interface ServiceSlideState {
  marginLeft: number;
}
export class ServiceSlide extends Component<
  ServiceSlideProps,
  ServiceSlideState
> {
  constructor(props) {
    super(props);
    this.state = {
      marginLeft: this.calcMarginLeft(),
    };
  }
  calcMarginLeft() {
    var marginLeft = 0;
    if (window.innerWidth < 2226.72)
      if (window.innerWidth > 1050)
        marginLeft = (2226.72 - window.innerWidth) / -2;
      else if (window.innerWidth > 580)
        marginLeft = (2226.72 - window.innerWidth) / -1.5;
      else marginLeft = -1015;
    return marginLeft;
  }
  handleResize() {
    this.setState({
      marginLeft: this.calcMarginLeft(),
    });
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize.bind(this));
  }
  render() {
    const isEvenPage = this.props.pageIndex % 2 === 0;
    const contentPosition = " content-last ";
    const LeftButton = () => (
      <>
        {this.props.pageIndex > 0 && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              background: "transparent",
              transform: "translateX(40px) translateY(-40px)",
            }}
          >
            <div className="paging-button-group">
              <DiagonalTextColorBackground
                backgroundColor="black"
                paddingLeft={10}
                paddingRight={20}
              >
                <Image
                  style={{
                    filter: "invert(1)",
                    transform: "rotate(180deg)",
                    marginTop: -3,
                    marginRight: 8,
                    width: 14,
                    height: 14,
                    animation: "left-side-slide 3s ease infinite",
                  }}
                  src={require("../../assets/images/arrow.png")}
                />{" "}
                <span style={{ fontWeight: 500, fontStyle: "italic" }}>
                  {titles[this.props.pageIndex - 1].title}
                </span>
              </DiagonalTextColorBackground>
            </div>
          </div>
        )}
      </>
    );

    const RightButton = () => (
      <div
        className="paging-next"
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          transform: "translateX(-40px) translateY(-40px)",
        }}
      >
        <div className="paging-button-group">
          <DiagonalTextColorBackground
            backgroundColor="black"
            paddingLeft={20}
            paddingRight={5}
          >
            <span style={{ fontWeight: 500, fontStyle: "italic" }}>
              {this.props.pageIndex < 3
                ? titles[this.props.pageIndex + 1].title
                : titles[0].title}
            </span>{" "}
            <Image
              src={require("../../assets/images/arrow.png")}
              style={{
                filter: "invert(1)",
                marginTop: -3,
                marginLeft: 4,
                width: 14,
                height: 14,
                animation: "right-side-slide 3s ease infinite",
              }}
            />
          </DiagonalTextColorBackground>
        </div>
      </div>
    );

    if (window.innerWidth > 580)
      return (
        <div className="service-slide">
          <div
            className={"services-image"}
            style={{ marginLeft: this.state.marginLeft }}
          >
            <DiagnolImage
              isRight={true}
              width={1113.36}
              height={770}
              imageProps={{
                style: { minWidth: 1113.36 },
                src: require("../../assets/images/services/" +
                  this.props.content.image),
              }}
            />
          </div>
          <div className={"service-content" + contentPosition}>
            <div className="service-title">
            <H1TitleWithDiagonalBackground id={"home-project-title"} phrase={titles[this.props.pageIndex].title} width={"420px"} fontSize={"40px"}/>

              {/* <DiagonalTitle title={titles[this.props.pageIndex].title} /> */}
            </div>
            <div className="service-article">
              <Diagnol containerClassName="service-article">
                <div style={{ marginBottom: "20px" }}>
                  {this.props.content.text}
                </div>
                <div style={{ marginBottom: "50px", listStyle: "none" }}>
                  {"What We Do Best:"}
                  {this.props.content.listItems.map((item, index) => (
                    <li key={index}>
                      {[...Array(10)].reduce(
                        (acc, space) => (acc = acc + "\xa0"),
                        ""
                      )}
                      <span>
                        <Image
                          style={{ width: "7px", height: "auto" }}
                          classes=""
                          alt="arrow black white"
                          src={
                            index % 2 === 0
                              ? require("../../assets/images/common2021/list-item-arrow-black.png")
                              : require("../../assets/images/common2021/list-item-arrow-white.png")
                          }
                        />{" "}
                      </span>
                      {item}
                    </li>
                  ))}
                </div>
              </Diagnol>
            </div>
          </div>
          <LeftButton />
          <RightButton />
        </div>
      );
    else
      return (
        <div className="service-slide">
          <MobileFlexibleView
            title={titles[this.props.pageIndex].title}
            imgSrc={require("../../assets/images/services/" +
              this.props.content.image)}
          >
            <div style={{ marginBottom: "20px" }}>
              {this.props.content.text}
            </div>
            <div style={{ marginBottom: "60px", listStyle: "none" }}>
              {"What We Do Best:"}
              {this.props.content.listItems.map((item, index) => (
                <li key={index} style={{ paddingLeft: "1rem" }}>
                  <span>
                    <Image
                      style={{ width: "7px", height: "auto" }}
                      classes=""
                      alt="arrow black white"
                      src={
                        index % 2 === 0
                          ? require("../../assets/images/common2021/list-item-arrow-black.png")
                          : require("../../assets/images/common2021/list-item-arrow-white.png")
                      }
                    />{" "}
                  </span>
                  {item}
                </li>
              ))}
            </div>
            <div
              style={{
                marginTop: 40,
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Image
                  style={{
                    transform: "rotate(180deg)",
                    marginTop: -3,
                    width: 35,
                    height: 35,
                  }}
                  src={require("../../assets/images/arrow.png")}
                />
              </div>
              <div>
                <Image
                  style={{
                    marginTop: -3,
                    width: 35,
                    height: 35,
                  }}
                  src={require("../../assets/images/arrow.png")}
                />
              </div>
            </div>
          </MobileFlexibleView>
        </div>
      );
  }
}
var titles = [
  { title: "design", width: 165 },
  { title: "manufacturing", width: 320 },
  { title: "fulfillment", width: 270 },
  { title: "onboarding", width: 265 },
];
