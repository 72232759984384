import React, {FC} from "react";
import "./Services.scss";
import {ServiceSlide} from "./ServiceSlide";
import {Carousel, CarouselItem} from "react-bootstrap";

export const Services:FC = () => {

    return (
      <section className="services">
        <div>
          <Carousel
          >
            {contents.map((content, index) => (
              <CarouselItem key={index} style={{ width: "100%" }}>
                <ServiceSlide content={content} pageIndex={index} />
              </CarouselItem>
            ))}
          </Carousel>
        </div>
      </section>
    );

}
var contents = [
  {
    title: "DESIGN",
    text: "Good design stands out, but great design stands alone. Intelligent design vs Evolution? In the Sacatelle Universe you can have both. We start with a consultation and review your brand guidelines and the project's goals, then our crew gets to work. Whether you need a simple logo on a stock item or a fully custom brand built from the ground up, our goal is to understand your vision, and turn your dreams into reality. Our design team takes into account all of your brand’s wants and needs, then seamlessly translates them into stellar products.",
    listTitle: "What We Do Best:",
    listItems: [
      "Product Development",
      "Branding Guideline Development",
      "Packaging Engineering",
      "Experiential Concept Development",
    ],
    image: "design/large.png",
  },
  {
    title: "MANUFACTURING",
    text: "Dream BIG… Space may be taking manufacturing off-planet in the near future, but till then - we’ve got you covered across the globe. Within our global supply chain we prioritize factories that champion sustainability and labor equality, and give you personalized insights into each factory performance, certifications, and capabilities. Once your designs have been chosen, we will begin quoting your project through our partner factories to determine the best fit and that's when the countdown to blast off begins!",
    listTitle: "What We Do Best:",
    listItems: [
      "Factory Vetting",
      "Quality Control & Inspection",
      "Packaging Engineering",
      "Sustainability Consulting",
    ],
    image: "manufacturing/large.png",
  },
  {
    title: "FULFILLMENT",
    text: "Designs are done, product is completed, you've made it to space... Now what? Now it's time to head for the next planet aka Fulfillment. Don't let warehousing, kitting and fulfillment get in your way, let us complete your mission. With our vertically integrated fulfillment services, tech-driven inventory management, and warehouses across the country we can help you stay ahead of the curve and the competition. Shipping to the moon starts soon. We’re ready, are you?",
    listTitle: "What We Do Best:",
    listItems: [
      "Kitting Services",
      "Packaging Solutions",
      "Storage & Inventory Management",
      "Distributed Shipping & Return Management",
    ],
    image: "fulfillment/large.png",
  },
  {
    title: "ONBOARDING",
    text: "One crew for onboarding at scale colonizing a new planet? Starting a Space Force? Then you are probably going to need help with the onboarding process. We will support your brand in each step of the process from creative product design to large scale fulfillment, from infinity and beyond. New hire gear, scheduled drops, nationwide campaign rollouts, we do it all, and most importantly - we make it super easy for you. ",
    listTitle: "What We Do Best:",
    listItems: [
      "Supply Chain Management",
      "Kit Assembly + Distribution",
      "Technology Driven Inventory Tracking",
      "Reverse Logistics Management",
    ],
    image: "onboarding/large.png",
  },
];
