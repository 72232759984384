import React from "react";
import "./EmploymentGrid.scss";
import {ButtonWithHoverEffect} from "sacatelle-common";


function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
}

export const EmploymentGrid = ({data}) => {
console.log('data', data)
  return (
    <div>
      <div className="employmentGridHeader">
        <div style={{position: "relative", left: "-10px"}}>
          <span>Job Position</span>
        </div>
        <div style={{position: "relative", left: "-39px"}}>
          <span>Location</span>
        </div>
        <div style={{position: "relative", left: "-89px"}}>
          <span>Posted Date</span>
        </div>
      </div>
      {data &&
        data.employmentOpportunities &&
        data.employmentOpportunities.map((employmentOpportunity) => {
          const { id, jobPosition, location, createdDate } = employmentOpportunity;
          return (
            <div className="employmentGridBody" key={id}>
              <div>
                <span>{jobPosition}</span>
              </div>
              <div>
                <span>{location}</span>
              </div>
              <div>
                <span>{`${getFormattedDate(
                    new Date(employmentOpportunity.createdDate)
                  )}`}</span>
              </div>
              <div className="custom-btn">
                <ButtonWithHoverEffect 
                    height={"32px"} 
                    width={"130px"} 
                    id={"job-description"} 
                    href={`/jobdescription/${id}?title=description`} 
                    title={"description"} 
                />
                <ButtonWithHoverEffect 
                    height={"32px"} 
                    width={"130px"} 
                    id={"job-apply"} 
                    href={`/jobdescription/${id}?title=apply`} 
                    title={"apply"} 
                    jumpToId={"application"} 
                  />
              </div>
            </div>
          );
        })}
    </div>
  );
};
