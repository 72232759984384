import React from "react";
import styles from "../styles/SwaggerLogos.module.scss";
import apple from "../../../assets/images/swagger-logos/apple.png";
import champion from "../../../assets/images/swagger-logos/champion.png";
import amazon from "../../../assets/images/swagger-logos/amazon.png";
import caa from "../../../assets/images/swagger-logos/caa.png";
import lafitness from "../../../assets/images/swagger-logos/lafitness.png";
import wholefoods from "../../../assets/images/swagger-logos/wholefoods.png";
import lacoste from "../../../assets/images/swagger-logos/lacoste.png";
import kith from "../../../assets/images/swagger-logos/kith.png";
import covergirl from "../../../assets/images/swagger-logos/covergirl.png";
import fedex from "../../../assets/images/swagger-logos/fedex.png";

const SwaggerLogos = () => (
  <div className={styles.swaggersSection}>
    <div className={styles.swaggersTitle}>OUR EXTENDED FAMILY</div>
    <div className={styles.swaggerLogos}>
      <div className={styles.swaggerLogoContainer}>
        <img src={apple} className={styles.swaggerLogo} />
      </div>
      <div className={styles.swaggerLogoContainer}>
        <img
          src={champion}
          className={styles.swaggerLogo}
          style={{ marginTop: 3 }}
        />
      </div>
      <div className={styles.swaggerLogoContainer}>
        <img
          src={amazon}
          className={styles.swaggerLogo}
          style={{ marginTop: 3 }}
        />
      </div>
      <div className={styles.swaggerLogoContainer}>
        <img
          src={caa}
          className={styles.swaggerLogo}
          style={{ marginTop: 5 }}
        />
      </div>
      <div className={styles.swaggerLogoContainer}>
        <img src={lafitness} className={styles.swaggerLogo} />
      </div>
      <div className={styles.swaggerLogoContainer}>
        <img src={wholefoods} className={styles.swaggerLogo} />
      </div>
      <div className={styles.swaggerLogoContainer}>
        <img
          src={lacoste}
          className={styles.swaggerLogo}
          style={{ marginTop: 5 }}
        />
      </div>
      <div className={styles.swaggerLogoContainer}>
        <img
          src={kith}
          className={styles.swaggerLogo}
          style={{ marginTop: 2 }}
        />
      </div>
      <div className={`${styles.swaggerLogoContainer} ${styles.coverGirl}`}>
        <img src={covergirl} className={styles.swaggerLogo} />
      </div>
      <div className={`${styles.swaggerLogoContainer} ${styles.fedex}`}>
        <img
          src={fedex}
          className={styles.swaggerLogo}
          style={{ marginTop: -4 }}
        />
      </div>
    </div>
  </div>
);

export default SwaggerLogos;
