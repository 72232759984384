import React, {useState} from "react";
import {
  ButtonWithHoverEffect,
  InputBoxComponent,
  InputComponent,
  MessageSent
} from "sacatelle-common";
import {gql, useMutation} from "@apollo/client";

const CompactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [tellus, setTellus] = useState("");
  const [company, setCompany] = useState("");
  const [indicator, setIndicator] = useState<boolean>(false);

  const SEND_CONTACT_EMAIL = gql`
    mutation sendEmail($message:String!, $subject: String!, $to: String!){
      sendEmail(message: $message,  subject: $subject, to: $to, )
    }
  `
  const [mutateContactEmail] = useMutation(SEND_CONTACT_EMAIL)

  const onSubmit = async (e) => {
    e.preventDefault();
    mutateContactEmail({
      variables: {
        to: "info@sacatelle.com",
        subject: "Contact Form",
        message: `<div><p>Name: ${name}</p><p>Email: ${email}</p><p>Phone: ${phone}</p><p>Company: ${company}</p><p>Comments: ${tellus}</p></div>`,
      }
    }).then((result) => {
      result.data.sendEmail === true
          ? setIndicator(true)
          : setIndicator(false);
    })
  };

  return (
    <form
      onSubmit={onSubmit}
      className="contact-form flex-column"
    >
      <div className="flex-column">
        <div
          className="flex-row"
          style={{ justifyContent: "space-around", marginBottom: 20 }}
        >
          <div>
            <InputComponent
              type={"text"}
              className="textbox-contact"
              id={"name"}
              name={"name"}
              onChange={(e) => setName(e.target.value)}
              value={name}
              label={"YOUR NAME"}
              required={true}
            />
          </div>
          <div>
            <InputComponent
              type={"text"}
              className="textbox-contact"
              id={"company"}
              name={"company"}
              onChange={(e) => setCompany(e.target.value)}
              value={company}
              label={"COMPANY NAME"}
              required={true}
            />
          </div>
        </div>
        <div
          className="flex-row"
          style={{ justifyContent: "space-around", marginBottom: 10 }}
        >
          <div>
            <InputComponent
              type={"email"}
              className="textbox-contact"
              id={"email"}
              name={"email"}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              label={"EMAIL"}
              required={true}
            />
          </div>
          <div>
            <InputComponent
              type={"text"}
              className="textbox-contact"
              id={"phone"}
              name={"phone"}
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              label={"PHONE (OPTIONAL)"}
            />
          </div>
        </div>
        <div
          className="flex-column"
          style={{
            justifyContent: "center",
            textAlign: "center",
            margin: "0 auto",
            padding: 0,
            width: "100%",
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <InputBoxComponent
              className="textarea-contact"
              id={"tellus"}
              name={"tellus"}
              onChange={(e) => setTellus(e.target.value)}
              value={tellus}
              label={"LEAVE A MESSAGE"}
            />
          </div>
        </div>
        <div style={{ margin: "0 auto" }}>
          <ButtonWithHoverEffect width="150px" height="50px" title="SEND" type="submit"  buttonHandler={()=>{}} />
        </div>
          <div style={{ textAlign: "center", marginTop: 5 }}>{indicator && <MessageSent />}</div>
      </div>
    </form>
  );
};

export default CompactForm;
