import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { ErrorHandlingApolloProvider } from "sacatelle-apollo-client";


const localGraphQL = process.env.REACT_APP_GRAPHQL;
const rootElement = document.getElementById("root");
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");


ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <ErrorHandlingApolloProvider uri={localGraphQL}>
      <App />
    </ErrorHandlingApolloProvider>
  </BrowserRouter>,
  rootElement
);

registerServiceWorker();
