import React from "react";
import Universe from "../../../../assets/images/about/universe-mobile.png";
import { MobileFlexibleView } from "../../../shared/MobileFlexibleView";

export const OurMission = ({ width }) => (
  <div
    style={{
      justifyContent: "center",
      padding: 0,
      margin: "0 auto",
      marginBottom: -30,
      marginTop: -85,
      height: 600,
    }}
    className="about-text"
  >
    <MobileFlexibleView
      title={
        <div>
          <div
            className="our-mission-title our-mission-title-mobile"
            style={{
              position: "absolute",
                top: 10,
                paddingLeft:5,
                paddingRight: 10,
                paddingBottom: 15,
              marginLeft: width < 500 ? -170 : -50,
            }}
          >
            <p>A UNIVERSE</p>
          </div>
          <div
            className="our-mission-title our-mission-title-mobile"
            style={{
              marginTop: 97,
              position: "absolute",
              paddingLeft:5,
                paddingRight: 10,
                paddingBottom: 15,
              marginLeft: width < 500 ? -120 : 0,
            }}
          >
            <p>OF DIFFERENCE</p>
          </div>
        </div>
      }
      imgSrc={Universe}
    >
      <div>
        <br />
        From design through delivery, our mission is to exceed all expectations.
        In 2015 Sacatelle emerged on the promotional marketing scene and quickly
        found incredible success with brands such as the New York Times,
        Postmates, and Red Bull. Through creative design, pushing the industry’s
        conventional limitations, Sacatelle was able to set themselves apart
        from the competition. The company's vision is to reinvent consumer
        marketing by testing the creative boundaries of brand engagement, all
        while having a blast doing it.
      </div>
    </MobileFlexibleView>
  </div>
);

export default OurMission;
