import React from "react";
import About1 from "../../../../assets/images/about/about-1.png";
import About2 from "../../../../assets/images/about/about-2.png";
import About3 from "../../../../assets/images/about/about-3.png";

export const WhoWeAre = () => (
  <div className="who-we-are-container">
    <div
      style={{
        width: "34%",
        zIndex: 1000,
        transform: "translateY(40px)",
      }}
    >
      <img src={About2} style={{ width: "100%" }} />
    </div>
    <div
      style={{
        width: "63%",
        zIndex: 1000,
        marginLeft: "-100%",
        marginRight: "-100%",
        transform: "translateY(-70px) translateX(73px)",
      }}
    >
      <img src={About3} style={{ width: "100%" }} />
      <div className="who-we-are-title">
        <p>
          WHO WE ARE
          <br />
          <span style={{ paddingRight: 25 }}>AND WHAT WE LOVE</span> <br />
          <span style={{ paddingRight: 45 }}> IN A NUTSHELL</span>
        </p>
      </div>
    </div>
    <div
      style={{
        width: "45%",
        zIndex: 1000,
        transform: "translateY(120px)",
      }}
    >
      <img src={About1} style={{ width: "100%" }} />
    </div>
  </div>
);
